// export const Base_Url = 'https://hnhpapa.com/canteenadmin/api/v1';
// export const Image_Url = 'https://hnhpapa.com/canteenadmin/'
// export const Image_Url = 'https://teachercanteen.akprojects.co/'


// live build
// export const Image_Url = 'https://hnhteacher.com/teacheradmin/'
// export const Base_Url = 'https://hnhteacher.com/teacheradmin/api/v1'

// export const Base_Url = 'https://teachercanteen.akprojects.co/api/v1';

// export const Base_Url = 'https://canteen.akprojects.co/api/v1';
// export const Base_Url = 'https://hnhpapa.com/canteenadmin/api/v2';

// export const Image_Url = 'https://canteennew.akprojects.co/'

// export const Base_Url = 'https://canteennew.akprojects.co/api/v2';
// export const Base_Url = 'https://teachercanteen.akprojects.co/api/v1';

// export const PDF_URL = 'http://canteennew.akprojects.co/api/v2';



// testurl
// export const Image_Url = 'https://teachercanteen.akprojects.co/'
// export const Base_Url = 'https://teachercanteen.akprojects.co/api/v1';



// live build
export const Image_Url = 'https://hnhteacher.com/teacheradmin/'
export const Base_Url = 'https://hnhteacher.com/teacheradmin/api/v1'