import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { Base_Url } from './globalvariable/globe';
import { useTranslation } from './LanguageContext';
import MyNavbar from './Navbar';
import { Link } from 'react-router-dom';
import Loader from './Loader';

const Notification = () => {

    const [message, setMessage] = useState('');
    const [datas, setData] = useState([])
    const { translate, changeLanguage } = useTranslation();
    const [loading, setLoading] = useState(false)
    const { selectedLanguage } = useTranslation();

    const viewNoti = async () => {

        let bodyFormData = new FormData()
        const parentId = await localStorage.getItem('hnhpapauserId');
        const storedValues = localStorage.getItem('hnhpapaSchoolId');


        console.log("parent", parentId)
        bodyFormData.append('teacherId', parentId)
        // bodyFormData.append('teacherId', parentId)

        let responce = await axios({
            method: 'post',
            url: Base_Url + '/notification',
            data: bodyFormData,
            dataType: 'json',
            headers: { "Content-Type": "multipart/form-data" }
        })
            .then(async (res) => {
                if (res.data.status === true) {

                    setMessage(res.data.message)
                    setData(res.data.result);
                    setLoading(false)

                }
            })
            .catch((err) => {
                console.log('Error fetching data:', err);
                // setLoading(false)

            });
    }
    useEffect(() => {
        setLoading(true)
        viewNoti()
    }, [])

    return (
        loading ? (
            <Loader />
        ) : (
            <>
                <Container>
                    <MyNavbar />

                    <div className="my-4">
                        <Row className=" ">
                            <Col xs={7} sm={7} md={5} lg={3} xl={3}>

                                <Link to="/account" className="link-no-underline">
                                    <img src="leftarrow.png" alt="School Logo" className="pe-2" />
                                    {translate('Notification')}
                                </Link>
                            </Col>
                        </Row>
                    </div>
                    <div className="my-2 mx-3">

                        {datas && datas.length > 0 ? (
                            datas.map((item, index) => (
                                <div>
                                    <Row className="mx-1">
                                        <div className="border border-gray-100 my-1 py-1 mt-2 px-3 rounded" >
                                            <Row className="flex align-items-center ">
                                                <Col xs={2} sm={2} md={2} lg={2} xl={2} className="">
                                                    <img src="childprofile.png" alt="School Logo" />
                                                </Col>
                                                <Col xs={10} sm={10} md={10} lg={10} xl={10} className="text-start">
                                                    <div style={{ fontSize: '15px' }} className="font-weight-bold text-black ">
                                                        {selectedLanguage === 'en' && (
                                                            <>
                                                                {item.NotificationTittle}

                                                            </>

                                                        )}
                                                        {selectedLanguage === 'es' && (
                                                            <>
                                                                {item.NotificationTittleChinese}

                                                            </>

                                                        )}
                                                    </div>
                                                    <div style={{ fontSize: '15px' }} className="placecolor  text-muted  color-white ">
                                                        {selectedLanguage === 'en' && (
                                                            <>
                                                                {item.NotificationDescription}

                                                            </>

                                                        )}
                                                        {selectedLanguage === 'es' && (
                                                            <>
                                                                {item.NotificationDescriptionChinese}

                                                            </>

                                                        )}
                                                    </div>
                                                </Col>
                                                <Row>
                                                    <div className="text-end">

                                                        {item.NotificationCreatedOn}


                                                    </div>
                                                </Row>
                                            </Row>
                                            <Row>
                                            </Row>
                                        </div>
                                    </Row>

                                </div>
                            ))
                        ) : (

                            <Col lg={12} xl={12} md={12}>
                                <Row className="mx-1">
                                    <div className="border border-gray-100 my-1  py-3 px-3">
                                        <p style={{ color: '#E85C33' }}>{translate('No Notifications Found')}</p>
                                    </div>
                                </Row>
                            </Col>
                        )}
                    </div>

                </Container>
            </>
        )

    )
}

export default Notification