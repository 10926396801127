import React, { useEffect, useState } from 'react';
import axios from 'axios'; // Using axios to call the API
import { Base_Url } from './globalvariable/globe';
import { Container, Modal, Button, Spinner, Col, Row, Nav, Tab, Card } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useTranslation } from './LanguageContext';
import Loader from './Loader';
import MyNavbar from './Navbar';
import { ChevronLeft } from 'lucide-react'

const Ewallet = () => {
  const [walletAmount, setWalletAmount] = useState(0);
  const [fundAmount, setFundAmount] = useState(0);
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [isModalVisible, setModalVisible] = useState(false);
  const [isFundModalVisible, setFundModalVisible] = useState(false);
  const { translate, changeLanguage } = useTranslation();
  const [activeTab, setActiveTab] = useState('transactions');

  const [isLoading, setIsLoading] = useState(false);


  //   const handlePaymentSubmit = (val, paymentmethods) => {
  //     // Assume that Razorpay or any other payment gateway setup will go here.
  //     console.log('Payment details:', val, paymentmethods);
  //   };
  const handlePaymentSubmit = (val, meth) => {
    let amt = val.amount * 100

    var options = {
      // key: "rzp_live_HDJaK8R0U28N2k",
      key: "rzp_test_6rfv0AXZzpXeMu",
      amount: amt,
      currency: val.currency,
      name: "canteen app",
      order_id: val.id,
      description: "for testing purpose",
      handler: function (response) {
        if (response.razorpay_payment_id) {
          //   handleSelectChild()

          //   setTimeout(() => {
          //     handleSelectChildHide()
          //     navigate('/history')
          //   }, 3000);
        }

      },
      prefill: {
        name: val.notes.userName,
        email: val.notes.userEmail,
        contact: val.notes.userPhone
      },
      notes: {
        address: ""
      },
      theme: {
        color: "#3399cc"
      },
      modal: {
        ondismiss: function () {
          console.log('Checkout form closed');
          //   cartList(teacherId, schoolId)
        }
      }
    };

    // amount value is in multiples of 10000 
    options.config = {
      display: {
        blocks: {
          banks: {
            name: 'Pay via Fpx',
            instruments: meth
          },
        },
        sequence: ['block.banks'],
        preferences: {
          show_default_blocks: false,
        },
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.on('payment.failed', function (response) {
      console.log('Payment Failed:', response);
      // Handle failure logic here
      toast.error('Payment Failed! Please try again or choose a different payment method.');
      // setTimeout(() => {
      // }, 3000);

    });

    paymentObject.on('payment.modal.close', function () {
      console.log('Payment modal closed');

    });
    setIsLoading(false)

    paymentObject.open();

  }

  const pay = async () => {
    setIsLoading(true);
    const parentIDString = localStorage.getItem('hnhpapauserId');
    const bodyFormData = new FormData();
    bodyFormData.append('TeacherID', parentIDString);
    bodyFormData.append('walletamount', inputValue);

    try {

      const response = await axios.post(`${Base_Url}/addewallet`, bodyFormData);
      if (response.data.status === true) {
        handlePaymentSubmit(response.data.result.paymentDetails, response.data.result.paymentmethods);
        setInputValue('');
        setModalVisible(false);
        // localStorage.setItem('hnhpapateacherwallet', response.data.result.walletbalance);
      }
    } catch (err) {
      console.log('Error during wallet payment:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const Refund = async () => {
    setIsLoading(true);
    const parentIDString = localStorage.getItem('hnhpapauserId');
    const bodyFormData = new FormData();
    bodyFormData.append('TeacherID', parentIDString);
    bodyFormData.append('Amount', fundAmount);

    try {

      const response = await axios.post(`${Base_Url}/fundrequest`, bodyFormData);

      if (response.data.status === true) {
        setFundModalVisible(false);
        // localStorage.setItem('hnhpapateacherwallet', response.data.result.walletbalance);
      }
    } catch (err) {
      console.log('Error during wallet payment:', err);
    } finally {
      setIsLoading(false);
    }
  };

  // Function to fetch the wallet data from an API
  const fetchWalletData = async () => {

    const storedValue = localStorage.getItem('hnhpapauserId')
    try {
      //   const response = await axios.get('YOUR_API_ENDPOINT'); // Replace with your actual API endpoint

      let response = await axios({
        method: 'post',
        url: `${Base_Url}/wallethistory`,
        data: {
          TeacherID: storedValue,

        }
      })
      const data = response.data.result;

      // Set the wallet amount and history from the API response
      setWalletAmount(data.walletamount);
      setTransactionHistory(data.ewallethistory);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching wallet data:', error);
    }
  };

  const fetchFund = async () => {

    const storedValue = localStorage.getItem('hnhpapauserId')
    try {
      //   const response = await axios.get('YOUR_API_ENDPOINT'); // Replace with your actual API endpoint

      let response = await axios({
        method: 'post',
        url: `${Base_Url}/fundrequestlist`,
        data: {
          TeacherID: storedValue,

        }
      })
      const data = response.data.data[0].FundRequestAmount;

      // Set the wallet amount and history from the API response
      setFundAmount(data);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching wallet data:', error);
    }
  };

  // Fetch the wallet data when the component mounts
  useEffect(() => {
    setIsLoading(true);
    fetchWalletData();
    fetchFund();
  }, []);


  const handleSelect = (selectedTab) => {
    setActiveTab(selectedTab);
  };

  console.log("op", fundAmount)

  return (

    <Container fluid className="px-0 mx-auto" style={{maxWidth:"86%"}}>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <MyNavbar />

          {/* Header */}
          <Row className="bg-white py-3 mb-4 shadow-sm">
            <Col xs={12} className="d-flex align-items-center">
              <Link to="/" className="text-dark">
              </Link>
              <h1 className="h5 mb-0 mx-auto">E-Wallet</h1>
            </Col>
          </Row>

          <Container>
            {/* Balance Section */}
            <h2 className="h6 mb-3">Available Balance</h2>
            <Card className="bg-danger text-white mb-4" style={{ backgroundColor: '#ff4500' }}>
              <Card.Body className="d-flex justify-content-between align-items-center py-3">
                <span>Wallet Balance</span>
                <span className="h2 mb-0">{walletAmount}</span>
              </Card.Body>
            </Card>

            {/* Action Buttons */}
            <Row className="mb-4 align-items-center">
              <Col xs={5}>
                <small className="text-muted">
                  Recharge<br />your wallet
                </small>
              </Col>
              <Col xs={7} className="d-flex justify-content-end">
                <Button
                  variant="outline-danger"
                  className="me-2"
                  style={{ borderColor: '#ff4500', color: '#ff4500' }}
                  onClick={() => setModalVisible(true)}
                >
                  Add Money
                </Button>
                <Button
                  variant="outline-danger"
                  onClick={() => setFundModalVisible(true)}
                  style={{ borderColor: '#ff4500', color: '#ff4500' }}
                >
                  Refund
                </Button>
              </Col>
            </Row>

            {/* Transaction Tabs */}
            <Tab.Container defaultActiveKey="transactions">
              <Nav variant="tabs" className="mb-3 border-bottom-0">
                <Nav.Item>
                  <Nav.Link
                    eventKey="transactions"
                    className={`border-0 px-0 me-4 ${activeTab === 'transactions' ? '' : 'text-muted'}`}
                    style={{
                      color: activeTab === 'transactions' ? '#ff4500' : 'grey',
                      borderBottom: activeTab === 'transactions' ? '2px solid #ff4500' : 'none',
                    }}
                    onClick={() => handleSelect('transactions')}

                  >
                    Transaction History
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="refunds"
                    className={`border-0 px-0 ${activeTab === 'refunds' ? '' : 'text-muted'}`}
                    style={{
                      color: activeTab === 'refunds' ? '#ff4500' : 'grey',
                      borderBottom: activeTab === 'refunds' ? '2px solid #ff4500' : 'none',
                    }}
                    onClick={() => handleSelect('refunds')}

                  >
                    Refund History
                  </Nav.Link>
                </Nav.Item>
              </Nav>

              <Tab.Content>
                <Tab.Pane eventKey="transactions">
                  {transactionHistory?.map((txn) => (
                    <Card key={txn.EWalletID} className="mb-3 border-0 shadow-sm">
                      <Card.Body>
                        <Row>
                          <Col xs={9}>
                            <p className="mb-1">{txn.EWalletDescription}</p>
                            <small className="text-muted d-block">Tx.Id: {txn.EWalletTxnID}</small>
                            <small style={{ color: '#ff4500' }}>{txn.EWalletCreatedOn}</small>
                          </Col>
                          <Col xs={3} className="text-end">
                            <p className={`mb-0 ${txn.EWalletStatus === 'Success' ? 'text-success' : 'text-danger'}`}>
                              +{txn.EWalletTxnAmount}
                            </p>
                            <small className={txn.EWalletStatus === 'Success' ? 'text-success' : 'text-danger'}>
                              {txn.EWalletStatus}
                            </small>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  ))}
                </Tab.Pane>
                <Tab.Pane eventKey="refunds">
                  <p className="text-center text-muted py-4">No refund history available.</p>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Container>

          {/* Add Money Modal */}
          <Modal show={isModalVisible} onHide={() => setModalVisible(false)} centered>
            <Modal.Header closeButton>
              <Modal.Title>Add Money</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <input
                type="number"
                placeholder="Enter amount"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                className="form-control"
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setModalVisible(false)}>
                Cancel
              </Button>
              <Button
                variant="danger"
                style={{ backgroundColor: '#ff4500', borderColor: '#ff4500' }}
                onClick={pay}
              >
                Add Money
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={isFundModalVisible} onHide={() => setFundModalVisible(false)} centered>
            <Modal.Header closeButton>
              <Modal.Title>Refund Money</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <input
                type="number"
                placeholder="Enter amount"
                value={fundAmount}
                onChange={(e) => setInputValue(e.target.value)}
                className="form-control"
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setModalVisible(false)}>
                Cancel
              </Button>
              <Button
                variant="danger"
                style={{ backgroundColor: '#ff4500', borderColor: '#ff4500' }}
                onClick={Refund}
              >
                Refund Money
              </Button>
            </Modal.Footer>
          </Modal>

          <ToastContainer />
        </>
      )}
    </Container>
  );
};

export default Ewallet;
