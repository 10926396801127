import React, { useEffect, useState } from 'react';
import './custom.css'
import { Container } from 'react-bootstrap'
import { Row } from 'react-bootstrap'
import { Col } from 'react-bootstrap'
import MyNavbar from './Navbar';
import { ListGroup } from 'react-bootstrap'
import { Form } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Base_Url, PDF_URL } from './globalvariable/globe';
import { Image_Url } from './globalvariable/globe';
// import MySimplecalendar from './Simplecalendar'; 
import { Modal } from 'react-bootstrap';
import { useTranslation } from './LanguageContext';
import { Dropdown } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from 'react-bootstrap/Spinner';
import Loader from './Loader';



export default function MyQrcalendar() {
  const [loading, setLoading] = useState(false);

  const { id } = useParams();
  const [currentDate, setCurrentDate] = useState(new Date());
  const { translate, changeLanguage } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const [image, setImage] = useState('');
  const [name, setname] = useState('')
  const [verifyname, setVerifyname] = useState(true)
  const [ioc, setIoc] = useState('');
  const [verifyIoc, setVerifyIoc] = useState(true)
  const [studentGrade, setStudentGrade] = useState('');
  const [verifyStudentGrade, setVerifyStudentGrade] = useState(true)
  const [childrenDetail, setChildrenDetail] = useState('')
  const [imageChildren, setImageChildren] = useState('')
  const [pdfChildren, setPdfChildren] = useState('')

  const [showSelectChildModal, setShowSelectChildModal] = useState(false);
  const [show, setShow] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [button, SetButton] = useState(false);
  const [parentId, setLocalStorageParentId] = useState('');
  const keyExistsx = localStorage.getItem('hnhpapacartCount') !== null;
  const [cartCount, setcartCount] = useState(keyExistsx ? localStorage.getItem('hnhpapacartCount') : 0);
  const [showRenewInfo, setShowRenewInfo] = useState(false)
  const [renewData, setRenewData] = useState([])
  const [currOrdrId, setCurrOrdrId] = useState(0)

  const [newCall,setNewCall] =useState(0)
  // const handleToggleClick = () => {
  //   setDropdownOpen(!isDropdownOpen);
  // };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  // const handleItemClick = () => {
  //   // Handle item click logic here
  //   setDropdownOpen(false); // Close the dropdown after an item is clicked
  // };
  const successToast = (e) => {
    toast.dismiss();
    toast.success(e);
  };
  const errorToast = (e) => {
    toast.dismiss();
    toast.error(e);
  };


  const handlePrevMonth = () => {
    const prevMonth = new Date(currentDate);
    prevMonth.setMonth(prevMonth.getMonth() - 1);
    setCurrentDate(prevMonth);
    const dateObject = new Date(prevMonth);

    const year12 = dateObject.getFullYear();
    const month12 = String(dateObject.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const formattedDate12 = `${year12}-${month12}`;
    calendarList(id, formattedDate12);
  };

  const handleNextMonth = () => {
    const nextMonth = new Date(currentDate);
    nextMonth.setMonth(nextMonth.getMonth() + 1);
    setCurrentDate(nextMonth);
    const dateObject = new Date(nextMonth);

    const year12 = dateObject.getFullYear();
    const month12 = String(dateObject.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const formattedDate12 = `${year12}-${month12}`;
    calendarList(id, formattedDate12);

  };


  const [schoolId, setLocalStorageValue] = useState('');
  const [children, setChildren] = useState('');
  const [list, setList] = useState([]);
  const [menuList, setMenuList] = useState([]);
  const [qr, setQr] = useState('');
  const [calendarData, setCalendarData] = useState('');
  const [setMonths, setMonth] = useState(currentDate.getMonth() + 1);
  const [setYears, setYear] = useState(currentDate.getFullYear());
  const [grade, setGrade] = useState([]);
  const [content, setContent] = useState('custom-select w-100  bg-white border-gray-800');
  const currentDate1 = new Date();
  const [bgClndr, setBgClndr] = useState(false)
  console.log(list, "lis")
  const year = currentDate1.getFullYear();
  const month = String(currentDate1.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate1.getDate()).padStart(2, '0');
  const [currDate, setCurrDate] = useState(`${year}-${month}-${day}`)
  const [currDay, setCurrDay] = useState(day)
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1)

  const [modalDesciption, setModalDescription] = useState('');
  const [modalRemark, setModalRemark] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [notes, setNotes] = useState('');
  const [showMenuDetModal, setMenuDetShowModal] = useState(false);
  const { selectedLanguage } = useTranslation();

  console.log(selectedMonth, "mont")
  const formattedDate = `${year}-${month}-${day}`;
  const formattedDatenew = `${day}-${month}-${year}`;
  let currentDateMonth;
  let currentDates;
  let currentDateYear;
  let a;
  let monthmpnth;
  let storedValue1;
  // alert(setdays);
  const boxStyle = {
    backgroundColor: '#EFEFEF',
    borderRadius: '12px',
    padding: '12px',

  }
  const remove = {
    color: '#E85C33',
    fontSize: '15px'


  }
  const schoolName = {
    fontSize: "15px"

  }
  const placeColor = {
    fontSize: "15px"

  }
  const total = {
    color: '#E85C33',
    fontSize: '17px'

  }
  const order = {
    color: '#303A5B',
    fontSize: '15px',
    fontWeight: 'bold'
  }
  const subtotal = {
    color: '#7C7C7C'

  }
  const check = {
    color: '#303A5B',


  }

  let navigate = useNavigate()
  const school = () => {
    setLoading(true)
    const keyExists = localStorage.getItem('hnhpapaSchoolId') !== null;
    const keyExists1 = localStorage.getItem('hnhpapauserId') !== null;
    if (keyExists) {
      const storedValue = localStorage.getItem('hnhpapaSchoolId');
      storedValue1 = localStorage.getItem('hnhpapauserId');
      setLocalStorageValue(storedValue);
      setLocalStorageParentId(storedValue1);
      viewchidren(storedValue, id);
      // calendarDetails(id);
      let years1;
      if (setMonths < 10) {
        years1 = setYears + '-0' + setMonths;
      } else {
        years1 = setYears + '-' + setMonths;
      }
      calendarList(id, years1);
      calendarMenu(formattedDate)
    } else {
      navigate('/welcome');
    }
  }
  const viewchidren = async (schoolId, childrenId) => {

    let bodyFormData = new FormData()
    bodyFormData.append('ChildrenSchoolID', schoolId)
    bodyFormData.append('ChildrenID', childrenId)
    let responce = await axios({
      method: 'post',
      url: Base_Url + '/viewchildrens',
      data: bodyFormData,
      dataType: 'json',
      headers: { "Content-Type": "multipart/form-data" }
    })
      .then(async (res) => {
        if (res.data.status === true) {
          setChildren(res.data.result)
          setList(res.data.result.Orders)
          setQr(res.data.result.ChildrenQrCode)
        }
      })
      .catch((err) => {
        console.log('Error fetching data:', err);
        // setLoading(false)

      });

  }
  //   const calendarDetails = async(childrenId) => {

  //     let bodyFormData = new FormData()
  //     bodyFormData.append('ChildrenID', childrenId)
  //     let responce = await axios({
  //       method : 'post',
  //       url : Base_Url+'/viewscandetails',
  //       data : bodyFormData,
  //       dataType : 'json',
  //       headers: { "Content-Type": "multipart/form-data" }
  //     })
  //     .then(async(res)=>{
  //       if(res.data.status===true){
  //         setMenuList(res.data.result)
  //       }
  //     })
  //     .catch((err) => {
  //       console.log('Error fetching data:', err);
  //     });

  // }

  const viewChildrenDetails = async (id) => {

    let bodyFormData = new FormData()
    bodyFormData.append('ChildrenID', id)
    let responce = await axios({
      method: 'post',
      url: Base_Url + '/childeditdetail',
      data: bodyFormData,
      dataType: 'json',
      headers: { "Content-Type": "multipart/form-data" }
    })
      .then(async (res) => {
        if (res.data.status === true) {
          setLoading(false)

          setChildrenDetail(res.data.result)
          setname(res.data.result.childrenDetails.ChildrenName);
          setIoc(res.data.result.childrenDetails.ChildrenICNumber);
          setStudentGrade(res.data.result.childrenDetails.ChildrenGrade)
          setImageChildren(Image_Url + res.data.result.childrenDetails.ChildrenImage)
          setPdfChildren(Image_Url + res.data.result.childrenDetails.ChildrenQrcodePdf)
        }
      })
      .catch((err) => {
        console.log('Error fetching data:', err);
        // setLoading(false)

      });

  }
  const getdetails = async () => {
    const parentIDString = localStorage.getItem('hnhpapauserId');
    if (parentIDString) {
      let bodyFormData = new FormData()
      bodyFormData.append('ParentID', parentIDString)
      let responce = await axios({
        method: 'post',
        url: Base_Url + '/childrensgrade',
        data: bodyFormData,
        dataType: 'json',
        headers: { "Content-Type": "multipart/form-data" }
      })
        .then(async (res) => {
          if (res.data.status === true) {
            setGrade(res.data.result)
          }
        })
        .catch((err) => {
          console.log('Error fetching data:', err);
          setLoading(false)
        });
    } else {
      navigate('/welcome')
    }
  }
  const handleSelectChild = () => {
    setShowSelectChildModal(true);
  }
  const handleSelectChildHide = () => {
    setShowSelectChildModal(false);
  }

  const [showAllLogs, setShowAllLogs] = useState(false);

  const convertTime = (inputTime) => {
    // Parse the input time
    const parsedTime = new Date(`2000-01-01T${inputTime}`);

    // Format the output time in 12-hour format
    const outputTime = parsedTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });

    return outputTime;
  };
  const convertDateTime = (inputTime) => {
    // Parse the input time
    const parsedTime = new Date(inputTime);

    // Format the output time in 12-hour format
    const outputTime = parsedTime.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true });

    return outputTime;
  };
  const handleDayClick = (day, month, year) => {

    let day1, month1, data;
    if (day < 10) {
      day1 = '0' + day;
    } else {
      day1 = day;
    }
    if (month < 10) {
      month1 = '0' + month;
    } else {
      month1 = month;
    }
    data = year + '-' + month1 + '-' + day1;
    calendarMenu(data);
    setCurrDate(data);
    setSelectedMonth(month1)
    setCurrDay(day)

  };
  // const editChildren = () => {
  //  console.log();
  // };

  const deleteAll = async () => {
    handleSelectChildHide();
    let bodyFormData = new FormData()
    bodyFormData.append('ChildrenID', id)
    let responce = await axios({
      method: 'post',
      url: Base_Url + '/removechildrens',
      data: bodyFormData,
      dataType: 'json',
      headers: { "Content-Type": "multipart/form-data" }
    })
      .then(async (res) => {
        if (res.data.status === true) {
          setShow(true)
          setTimeout(() => {
            navigate('/children')
          }, 1000);
        }
      })
      .catch((err) => {
        console.log('Error fetching data:', err);
      });


  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const editChildren = () => {
    setShowModal(true);
  };
  const handleProfileImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file.type;
      if (fileType === 'image/jpeg' || fileType === 'image/png') {
        const reader = new FileReader();
        reader.onload = (event) => {
          setProfileImage(event.target.result);
        };
        reader.readAsDataURL(file);
        setImage(file);
      } else {
        alert(`${translate('Please select a valid image file')} `);


        e.target.value = null;
      }
    }
  };
  const checkname = (e) => {
    setname(e.target.value);
    setVerifyname(true);
    // valid(true)
  }
  const checkIoc = (e) => {
    setIoc(e.target.value);
    setVerifyIoc(true);
    // valid(true)
  }
  const checkStudentGrade = (e) => {
    setStudentGrade(e.target.value);
    setVerifyStudentGrade(true);
    setContent('custom-select w-100  bg-white border-gray-800')
    // valid(true)
  }
  const getImg = async () => {

    let bodyFormData = new FormData()
    bodyFormData.append('ChildID', id)
    let responce = await axios({
      method: 'post',
      url: Base_Url + '/generatepdf',
      data: bodyFormData,
      dataType: 'json',
      headers: { "Content-Type": "multipart/form-data" }
    })
      .then(async (res) => {
        if (res.data.status === true) {
          console.log(res.data)
          downloadPDF(res.data.file_path)

        }
      })
      .catch((err) => {
        console.log('Error fetching data:', err);
        setLoading(false)
      });

  }
  const getShareImg = async () => {

    let bodyFormData = new FormData()
    bodyFormData.append('ChildID', id)
    let responce = await axios({
      method: 'post',
      url: Base_Url + '/generatepdf',
      data: bodyFormData,
      dataType: 'json',
      headers: { "Content-Type": "multipart/form-data" }
    })
      .then(async (res) => {
        if (res.data.status === true) {
          console.log(res.data)
          shareImage(res.data.file_path)
          
        }
      })
      .catch((err) => {
        console.log('Error fetching data:', err);
        setLoading(false)
      });

  }
  const addChildren = async () => {

    if (name == "" || studentGrade == "") {
      if (name == "") {
        setVerifyname(false)
      }
      if (name == "") {
        setVerifyStudentGrade(false)
        setContent('errorField custom-select w-100  bg-white border-gray-800')
      }
    } else {
      SetButton(true)
      const formData = new FormData();
      formData.append('ChildrenID', id);
      formData.append('ChildrenName', name);
      // formData.append('ChildrenICNumber', ioc);
      formData.append('ChildrenGrade', studentGrade);
      if (image != '') {
        formData.append('ChildrenImage', image);
      }
      let response = await axios({
        method: 'post',
        url: `${Base_Url}/editchildrens`,
        data: formData,
        dataType: 'json',
        headers: { "Content-Type": "multipart/form-data" }
        // data : {
        //   ChildrenID : id,
        //   ChildrenName :name,
        //   ChildrenICNumber :ioc,
        //   ChildrenGrade :studentGrade,
        //   // ChildrenImage :image,
        // }
      })
        .then((res) => {
          if (res.data.status == true) {
            handleCloseModal()
            handleDropdownToggle()
            // navigate('/children')
            // successToast(`${translate('Children Updated Successfully')} `);
            successToast(`${translate('Children Updated Successfully, Please Download the New QR Code.')} `);

            setTimeout(() => {
              setNewCall(newCall+1)
            }, 4000);
            SetButton(false)
          } else {
            // valid(false)
            errorToast(res.data.errors)
            handleCloseModal()
            handleDropdownToggle()
            SetButton(false)
          }
        }).catch((error) => {
          console.error('Error', error)

        })

    }
  }
  const calendarMenu = async (datetime) => {

    let bodyFormData = new FormData()
    bodyFormData.append('ChildrenID', id)
    bodyFormData.append('ScanDate', datetime)
    let responce = await axios({
      method: 'post',
      url: Base_Url + '/viewfilterscans',
      data: bodyFormData,
      dataType: 'json',
      headers: { "Content-Type": "multipart/form-data" }
    })
      .then(async (res) => {
        if (res.data.status === true) {
          setMenuList(res.data.result)
          setLoading(false)

        } else {
          setMenuList([])
          setLoading(false)

        }
      })
      .catch((err) => {
        console.log('Error fetching data:', err);
        setLoading(false)

      });

  }
  const calendarList = async (id, year) => {

    let bodyFormData = new FormData()
    bodyFormData.append('ScanMonth', year)
    bodyFormData.append('ChildrenID', id)
    let responce = await axios({
      method: 'post',
      url: Base_Url + '/viewfiltercalender',
      data: bodyFormData,
      dataType: 'json',
      headers: { "Content-Type": "multipart/form-data" }
    })
      .then(async (res) => {
        if (res.data.status === true) {
          setCalendarData(res.data.result)
        }
      })
      .catch((err) => {
        console.log('Error fetching data:', err);
      });

  }

  const getcartCount = async () => {

    let response = await axios({
      method: 'post',
      url: `${Base_Url}/cartcount`,
      data: {
        TeacherID: parentId,
      }
    })
      .then((res) => {
        console.log('response', res);
        if (res.data.status == true) {
          setcartCount(localStorage.setItem('hnhpapacartCount', res.data.result.cartItemCount));
        } else {
          alert(res.data.message);
        }
      }).catch((error) => {
        console.error('Error', error)
      })
  }

  const generateCalendarDays = () => {
    const dot1Color = 'green';
    const dot2Color = 'gray';


    const daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
    const days = [];
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const offset = firstDayOfMonth.getDay();

    for (let i = 0; i < offset; i++) {
      days.push(
        <div key={`empty-${i}`} className="he calendar-day empty"></div>
      );
    }

    for (let i = 1; i <= daysInMonth; i++) {
      const dayDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), i);
      const isSunday = dayDate.getDay() === 0;
      days.push(
        <div
          key={i}
          id={`day-${i}`}
          className={`calendar-day ${isSunday ? 'sunday' : ''}`}
          onClick={() => handleDayClick(i, currentDate.getMonth() + 1, currentDate.getFullYear())}
        >
          <div className={`${currDay == i && currentDate.getMonth() + 1 == selectedMonth && currentDate.getFullYear() == year ? 'selected-day' : ''}`}>
            {i}
          </div>
          <div style={{ display: 'none' }}> {currentDateYear = currentDate.getFullYear()}</div>
          {(currentDate.getMonth() + 1) > 9 ? (
            <div style={{ display: 'none' }}>
              {currentDateMonth = currentDate.getMonth() + 1}

            </div>
          ) : (
            <div style={{ display: 'none' }}>
              {currentDateMonth = '0' + (currentDate.getMonth() + 1)}

            </div>
          )}
          {i > 9 ? (
            <div style={{ display: 'none' }}>
              {currentDates = i}

            </div>
          ) : (
            <div style={{ display: 'none' }}>
              {currentDates = '0' + i}

            </div>
          )}
          <div>
            {
              calendarData[currentDateYear + '-' + currentDateMonth + '-' + currentDates] && calendarData[currentDateYear + '-' + currentDateMonth + '-' + currentDates].length > 0 ?
                (calendarData[currentDateYear + '-' + currentDateMonth + '-' + currentDates].map((item, index) => (
                  <>

                    {item.ScanStatus == 'redeemed' ? (

                      <div className="dot ms-3" style={{ background: dot1Color }}></div>


                    ) : (

                      <div className="dot ms-3 " style={{ background: dot2Color }}></div>

                    )}
                  </>
                ))
                ) : (
                  <></>
                )}</div>

        </div>
      );
    }

    return days;
  };
  const renew1 = async (id) => {
    setShowRenewInfo(true)
    let bodyFormData = new FormData()
    bodyFormData.append('OrderID', id)
    let responce = await axios({
      method: 'post',
      url: Base_Url + '/renewmenu',
      data: bodyFormData,
      dataType: 'json',
      headers: { "Content-Type": "multipart/form-data" }
    })
      .then(async (res) => {
        if (res.data.status === true) {
          getcartCount();
          navigate('/cart')
        } else {
          alert(res.data.message)
        }
      })
      .catch((err) => {
        console.log('Error fetching data:', err);
      });

  }
  const renew = async (ordrId) => {
    setShowRenewInfo(true)
    let bodyFormData = new FormData()
    bodyFormData.append('orderID', ordrId)
    bodyFormData.append('childID', id)

    let responce = await axios({
      method: 'post',
      url: Base_Url + '/checkRenewal',
      data: bodyFormData,
      dataType: 'json',
      headers: { "Content-Type": "multipart/form-data" }
    })
      .then(async (res) => {
        if (res.data.status === true) {
          // getcartCount();
          // navigate('/cart')
          setRenewData(res.data.messages)
        } else {
          // alert(res.data.message)
        }
      })
      .catch((err) => {
        console.log('Error fetching data:', err);
      });

  }
  //   const calendarMenu = async(datetime) => {

  //     let bodyFormData = new FormData()
  //     bodyFormData.append('ChildrenID', id)
  //     bodyFormData.append('ScanDate', datetime)
  //     let responce = await axios({
  //       method : 'post',
  //       url : Base_Url+'/viewfilterscans',
  //       data : bodyFormData,
  //       dataType : 'json',
  //       headers: { "Content-Type": "multipart/form-data" }
  //     })
  //     .then(async(res)=>{
  //       if(res.data.status===true){
  //         setMenuList(res.data.result)
  //       }
  //     })
  //     .catch((err) => {
  //       console.log('Error fetching data:', err);
  //     });

  // }

  // Old Image download
  // const downloadImage = (imageUrl) => {
  //   const imageUrl = Image_Url + qr;

  //   const xhr = new XMLHttpRequest();
  //   xhr.open('GET', imageUrl, true);
  //   xhr.responseType = 'blob';

  //   xhr.onload = () => {
  //     const link = document.createElement('a');
  //     link.href = URL.createObjectURL(xhr.response);
  //     link.download = 'downloaded-image.png';

  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   };

  //   xhr.send();
  // };


  const downloadPDF = async (PDFUrl) => {
    // const PDFUrl = "http://canteennew.akprojects.co/public/pdfs/Qrcode-1715153363.pdf";

    // const xhr = new XMLHttpRequest();
    // xhr.open('GET', PDFUrl, true);
    // xhr.responseType = 'blob';

    // xhr.onload = () => {
    //   const blob = new Blob([xhr.response], { type: 'application/pdf' });
    //   const link = document.createElement('a');
    //   link.href = window.URL.createObjectURL(blob);
    //   link.download = 'downloaded-pdf.pdf';

    //   document.body.appendChild(link);
    //   link.click();

    //   // Cleanup
    //   document.body.removeChild(link);
    //   window.URL.revokeObjectURL(link.href);
    // };

    // xhr.send();

    //   try {
    //     const response = await fetch("https://canteennew.akprojects.co/public/pdfs/Qrcode-1715232106.pdf", {
    //       method: 'GET',
    //       mode: 'no-cors'
    //     })
    //     console.log(response,"blobsss")
    //     const blob = await response.blob();
    //     console.log(blob,"blob")
    //     const url = window.URL.createObjectURL(blob);
    //     const a = document.createElement('a');
    //     a.href = url;
    //     a.download = 'downloaded-pdf.pdf';
    //     document.body.appendChild(a);
    //     a.click();
    //     document.body.removeChild(a);
    //   } catch (error) {
    //     console.error('Error downloading PDF:', error);
    //   }
    // };

    try {
      // const pdfUrl = "https://canteennew.akprojects.co/public/pdfs/Qrcode-1715232106.pdf";
      const link = document.createElement('a');
      link.href = PDFUrl;
      link.download = "Qrcode.pdf"; // Specify the filename
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading PDF:', error);
    }
  };


  const shareImage = (pdfUrl) => {  
    navigator.clipboard.writeText(pdfUrl)
      .then(() => {
        toast.success(`${translate('URL copied to clipboard')} `);
      })
      .catch((error) => {
        console.error('Unable to copy URL to clipboard', error);
      });
  };


  const viewModal = (index) => {
    // const menuValuesForGroupId1 = getMenuValuesByGroupId(index);
    let title, description, remark, notes;

    if (selectedLanguage === 'en') {
      title = menuList[index].MenuTittleEnglish;
      description = menuList[index].MenuDescriptionEnglish;
      remark = menuList[index].MenuRemarksEnglishTop;
      notes = menuList[index].MenuRemarksEnglishBottom;
    } else if (selectedLanguage === 'es') {
      title = menuList[index].MenuTittleChinese;
      description = menuList[index].MenuDescriptionChinese;
      remark = menuList[index].MenuRemarksChineseTop;
      notes = menuList[index].MenuRemarksChineseBottom;
    }

    setModalDescription(description);
    setModalRemark(remark);
    setModalTitle(title);
    setNotes(notes)
    setMenuDetShowModal(true);
  }


  useEffect(() => {
    school();
    viewChildrenDetails(id);
    getdetails();
  }, [newCall])
  return (


    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <ToastContainer />
          <Container>
            <MyNavbar />

            <Row >
              <Col xs={5} sm={7} md={5} lg={3} xl={3} className="mt-4 ">
                <Link to="/children" className="link-no-underline ">
                  <img src={process.env.PUBLIC_URL + '/leftarrow.png'} alt="School Logo" className="pe-2 " />
                  {/* <p className='d-inline'>{children.ChildrenName}</p> */}
                  <p className='d-inline'>{translate('Child')}</p>

                </Link>
              </Col>


              <Col xs={7} sm={5} md={7} lg={9} xl={9} className="text-end">
                {/* <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic" style={{ background: 'white', border: 'white', float: 'right' }}>
                    <img src={process.env.PUBLIC_URL + '/button.svg'} />
                  </Dropdown.Toggle>

                  <Dropdown.Menu >
                    <Dropdown.Item href="" onClick={editChildren}>{translate('Edit')} <img src={process.env.PUBLIC_URL + '/edit.svg'} style={{ float: 'right' }} /></Dropdown.Item>
                    <Dropdown.Item href="" onClick={handleSelectChild}>{translate('Delete')} <img src={process.env.PUBLIC_URL + '/trash.svg'} style={{ float: 'right' }} /></Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown> */}
                <div style={{ position: 'relative', float: 'right' }}>
                  <img
                    src={process.env.PUBLIC_URL + '/button.svg'}
                    alt="Toggle Dropdown"
                    onClick={handleDropdownToggle}
                    style={{ cursor: 'pointer' }}
                    class="mt-4" />

                  {isDropdownOpen && (
                    <div
                      style={{
                        position: 'absolute',
                        top: '100%',
                        right: 0,
                        background: 'white',
                        border: '1px solid #ccc',
                        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
                        zIndex: 1000,
                        width: '150px',
                        borderRadius: '5px'
                        // Set your desired width here
                      }} class="pt-3 pb-3 ps-3 pe-3"
                    >
                      <div onClick={editChildren}>
                        <Row>
                          <Col xs={4} class="text-end">
                            {translate('Edit')}
                          </Col>
                          <Col xs={8}>
                            <img
                              src={process.env.PUBLIC_URL + '/edit.svg'}
                              alt="Edit"
                              style={{ float: 'right' }}
                            />
                          </Col>
                        </Row>
                      </div>
                      <div onClick={handleSelectChild}>
                        <Row>
                          <Col xs={5} class="text-start">
                            {translate('Delete')}
                          </Col>
                          <Col xs={7}>
                            <img
                              src={process.env.PUBLIC_URL + '/trash.svg'}
                              alt="Edit"
                              style={{ float: 'right' }}
                            />
                          </Col>
                        </Row>
                      </div>




                    </div>
                  )}
                </div>
              </Col>
            </Row>
            <Row className="my-5">
              <Col md={8} lg={8} xl={8}>
                <div style={boxStyle} className="border border-gray-100 my-1 ">

                  <Row className="flex align-items-center">
                    <Col xs={2} sm={3} md={3} lg={3} xl={1} className="text-end">
                      <img src={Image_Url + children.ChildrenImage} alt="School Logo" style={{ width: '44px', height: '44px', borderRadius: '44px' }} />
                    </Col>
                    <Col xs={7} sm={6} md={6} lg={6} xl={8} className="text-start">
                      <div style={schoolName} className="font-weight-bold text-black ">{children.ChildrenName}</div>
                      <div style={placeColor} className="placecolor  text-muted  color-white ">{children.ChildrenGrade}</div>
                    </Col>
                    <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                    </Col>
                  </Row>
                </div>


                <Row >
                  {list && list.length > 0 ? (list.map((item, index) => (
                    <Col lg={12} xl={6} md={12} >
                      <Row className="he mx-1" style={{ background: 'var(--layout-100, #F9F9F9)' }}>
                        <div key={index} style={boxStyle} className="border border-gray-100 my-1  py-3 px-3">
                          <Row className="flex align-items-center  ">
                            <Col xs={6} sm={6} md={6} lg={6} xl={6} className="text-start">
                              <div className="" style={{ color: '#1EAC81' }}>{translate(item.PlanCategory)}</div>
                            </Col>
                            <Col xs={6} sm={6} md={6} lg={6} xl={6} className="text-end">
                              <div>{item.OrderDate}</div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={7} lg={7} xl={6} sm={6} xs={6} className="mt-2">
                              <ul className="text-start" style={{ fontSize: '14px', color: '#7C7C7C' }}>

                                {item.OrderMenuDetails ? (item.OrderMenuDetails.map((item1, index) => (

                                  <>
                                    {selectedLanguage === 'en' && (
                                      <>

                                        <li>{item1.MenuTittleEnglish} - {item1.MenuTypeEnglish}</li>
                                      </>

                                    )}
                                    {selectedLanguage === 'es' && (
                                      <>
                                        <li>{item1.OrderedMenuTittleChinese} - {item1.OrderedMenuTypeChinese}</li>

                                      </>

                                    )}

                                  </>
                                ))
                                ) : (
                                  <p style={{ color: '#E85C33' }}>{translate('No menu details available')}</p>
                                )}
                              </ul>
                            </Col>
                            <Col md={5} lg={5} xl={6} sm={6} xs={6} className="justify-end align-items-end mt-5">
                              <div className="text-end">
                                {item.renewmenu == 1 ?
                                  <Button className="" style={{ width: '60%', backgroundColor: 'black', borderColor: 'black', color: 'white', fontSize: '15px', paddingLeft: '1px', paddingRight: '1px' }} onClick={() => { renew(item.OrderID); setCurrOrdrId(item.OrderID) }}>{translate('Renew Now')}</Button>
                                  : <>{item.renewmenumessage}</>}
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Row>
                    </Col>
                  ))
                  ) : (
                    !loading && !list ?
                      <Col lg={12} xl={6} md={12} >
                        <Row className="mx-1 mt-4">
                          <div style={boxStyle} className="border border-gray-100 my-1 py-3 ">
                            <p style={{ color: '#E85C33' }}>{translate('No Subscription Available')}</p>
                          </div>
                        </Row>
                      </Col> : null
                  )}

                </Row>
                <div style={{ color: 'var(--dark-blue, #161E40)', fontSize: '14px', fontStyle: 'normal', fontWeight: '500', lineHeight: 'normal', marginTop: '10px', marginLeft: '6px' }}>{translate('Booked Food Items on')} {currDate}</div>
                <Row>
                  <Col lg={12} xl={6} md={12} >
                    <Row className="mx-1 ">
                      {menuList.length == 0 ?
                        <div style={{ ...boxStyle, marginBottom: '5px' }} className="border border-gray-100  mt-4 py-3 px-3">
                          <p style={{ color: '#E85C33' }}>{translate('No Items for specified date')}</p>
                        </div>
                        // <div className='mt-5 ms-5' style={{ color: '#E85C33', fontSize: '14px', fontStyle: 'normal', fontWeight: '500', lineHeight: 'normal', marginTop: '10px', marginLeft: '6px' }}>{translate('*No items for specified date')} </div>
                        : null}</Row></Col></Row>

                {menuList && menuList.length > 0 ? (menuList.map((item, index) => (
                  <div>
                    <Row className="hee mx-1 mt-3" style={{ background: 'var(--layout-100, #F9F9F9)' }} >
                      <div style={boxStyle} className="border border-gray-100 my-1  py-3 px-3">
                        <Row className="mt-1 mb-3">
                          <Col lg={6} xl={8} md={6} xs={4} sm={4}>
                            <div className="text-start " style={{ color: '#303A5B', fontSize: '15px', fontWeight: 'bold' }}>
                              {selectedLanguage === 'en' && (
                                <>
                                  {item.MenuTittleEnglish}

                                </>

                              )}
                              {selectedLanguage === 'es' && (
                                <>
                                  {item.MenuTittleChinese}

                                </>

                              )}
                            </div>
                          </Col>

                          <Col lg={6} xl={4} md={6} xs={8} sm={8}>
                            <div className="text-end">
                              {item.ScanStatus == 'not-redeemed' ?
                                <Button className="" style={{ width: '60%', backgroundColor: '#E85C33', borderColor: '#E85C33', color: 'white', fontSize: '15px', paddingLeft: '1px', paddingRight: '1px', borderRadius: '15px' }}>{translate(item.ScanStatus)}</Button>
                                :
                                <Button className="" style={{ width: '60%', backgroundColor: '#188A67', borderColor: '#188A67', color: 'white', fontSize: '15px', paddingLeft: '1px', paddingRight: '1px', borderRadius: '15px' }}>{translate(item.ScanStatus)}</Button>
                              }
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6} xl={6} md={6} sm={6} xs={6}>
                            <div >
                              {selectedLanguage === 'en' && (
                                <>
                                  {item.MenuTypeEnglish}

                                </>

                              )}
                              {selectedLanguage === 'es' && (
                                <>
                                  {item.MenuTypeChinese}

                                </>

                              )}

                              <img
                                onClick={() => viewModal(index)}
                                className=" ms-1" src={process.env.PUBLIC_URL + '/info_Icon.png'} alt="info"
                                style={{ width: '18px', height: '18px', }}>

                              </img>
                            </div>
                          </Col>
                          <Col lg={6} xl={6} md={6} sm={6} xs={6} className="text-end text-muted" style={{ fontSize: '14px' }}>

                            {item.ScanTime ? <>{"@" + convertTime(item.ScanTime)}</> : null}
                          </Col>
                        </Row>
                        {item.log && item.log.length > 0 && (
                          <div className='ms-2 mt-2'>
                            {item.log.slice(0, showAllLogs ? item.log.length : 2).map((val, ind) => (

                              <ul className='p-0 m-0'>
                                <li style={{ color: 'var(--buttons-yellow-600, #FAB005)' }} className='p-0 m-0 ms-2'>

                                  <Row key={ind}>
                                    <Col lg={6} xl={6} md={6} sm={6} xs={6}>
                                      <div className='d-inline-flex ' >
                                        <div style={{ fontSize: '16px', color: 'var(--buttons-yellow-600, #FAB005)' }}>{val.OrderScanLogStatus == 'not-valid' ? "Invalid" : val.OrderScanLogStatus} </div>
                                        <p style={{ fontSize: '14px', color: ' var(--buttons-red-600, #FA5252)' }} className=' ms-2'>{translate('Scanned again')}</p>
                                      </div>
                                    </Col>

                                    <Col lg={6} xl={6} md={6} sm={6} xs={6} className="text-end text-muted" style={{ fontSize: '14px' }}>
                                      @ {val.OrderScanLogScanedOn ? convertDateTime(val.OrderScanLogScanedOn) : null}
                                    </Col>
                                  </Row>

                                </li>
                              </ul>

                            ))}
                            {item.log.length > 2 ? (
                              <div className="text-start mt-3">
                                {showAllLogs ? (
                                  <p style={{ cursor: 'pointer' }} className='text-primary' onClick={() => setShowAllLogs(false)}>{translate('View Less')}</p>
                                ) : (
                                  <p style={{ cursor: 'pointer' }} className='text-primary' onClick={() => setShowAllLogs(true)}>{translate('View More')}</p>
                                )}
                              </div>
                            ) : null}
                          </div>
                        )}

                      </div>
                    </Row>
                  </div>

                ))
                ) : (
                  !loading && !menuList ?
                    <Row className="mx-1 mt-3">
                      <div style={boxStyle} className="border border-gray-100 my-1  py-3 px-3">
                        <Row className="mt-1 mb-3">
                          <Col lg={12} xl={12} md={12} xs={12} sm={12}>
                            <p style={{ color: '#E85C33', marginLeft: '40%' }}>{translate('No Order Found')}</p>
                          </Col>
                        </Row>
                      </div>
                    </Row> : null
                )}


                {/* <Row className="mx-1 mt-3">
        <div style={boxStyle}className="border border-gray-100 my-1  py-3 px-3">
        <Row className="mt-1 mb-3">
<Col lg={6} xl={8} md={6} xs={6} sm={6}>

    <div className="text-start "style={{color:'#303A5B',fontSize:'15px',fontWeight:'bold'}}>Lunch</div>
    </Col>

    <Col lg={6} xl={4} md={6} xs={6} sm={6}>

    <div className="text-end">
<Button className=""style={{
      width: '60%',
      backgroundColor: '#188A67', 
      borderColor: '#188A67',    
      color:'white' ,
      fontSize:'15px',
      paddingLeft: '1px',
      paddingRight:'1px',
      borderRadius:'15px'
    }}>
Redeemed
    </Button></div>
    </Col>
</Row>


<Row>
  <Col lg={6} xl={6} md={6} sm={6} xs={6}>
    <div >Veg Premium</div></Col>
    <Col lg={6} xl={6}  md={6} sm={6} xs={6} className="text-end text-muted"style={{fontSize:'14px'}}>@9.30 a.m</Col></Row>
    


    

  
</div>
          </Row>
   






      <Row className="mx-1 mt-3">
        <div style={boxStyle}className="border border-gray-100 my-1  py-3 px-3">
        <Row className="mt-1 mb-3">
<Col lg={6} xl={8} md={6} xs={6} sm={6}>

    <div className="text-start "style={{color:'#303A5B',fontSize:'15px',fontWeight:'bold'}}>Snacks</div>
    </Col>

    <Col lg={6} xl={4} md={6} xs={6} sm={6}>

    <div className="text-end">
<Button className=""style={{
      width: '60%',
      backgroundColor: '#188A67', 
      borderColor: '#188A67',    
      color:'white' ,
      fontSize:'15px',
      paddingLeft: '1px',
      paddingRight:'1px',
      borderRadius:'15px'
    }}>
Redeemed
    </Button></div>
    </Col>
</Row>


<Row>
  <Col lg={6} xl={6} md={6} sm={6} xs={6}>
    <div >Veg Premium</div></Col>
    <Col lg={6} xl={6}  md={6} sm={6} xs={6} className="text-end text-muted"style={{fontSize:'14px'}}>@9.30 a.m</Col></Row>
    

<Row>
  <Col lg={4} xl={2} md={4} sm={3} xs={3}>
    <li style={{color:'#E85C33'}}>Invalid</li></Col>
    <Col lg={4} xl={6} md={4} sm={6} xs={5}className="" style={{color:'#FA5252',fontSize:'14px'}}>Scanned Again</Col>
    <Col lg={4} xl={4}  md={4} sm={3} xs={4} className="text-end text-muted"style={{fontSize:'14px'}}>@9.30 a.m</Col></Row>
    
    <Row>
  <Col lg={4} xl={2} md={4} sm={3} xs={3}>
    <li style={{color:'#E85C33'}}>Invalid</li></Col>
    <Col lg={4} xl={6} md={4} sm={6} xs={5}className="" style={{color:'#FA5252',fontSize:'14px'}}>Scanned Again</Col>
    <Col lg={4} xl={4}  md={4} sm={3} xs={4} className="text-end text-muted"style={{fontSize:'14px'}}>@9.30 a.m</Col></Row>
    

  
</div>
          </Row>
   




            <div>Breakfast</div>
        <div style={boxStyle}className="border border-gray-100 my-1  py-3 px-3">
        <div className="" style={{fontSize:'15px',fontWeight:'bold'}}>week 1</div>
<ul style={{fontSize:'15px'}}>
  <li>
肉酱意大利面+水果 Spaghetti Bolognese + Fruits</li>
<li>芝士午餐肉包+Milo Cheese & Luncheon Meat Bun + Milo</li>
<li>脆皮麦片鸡饭 Crispy Cereal Chicken Rice</li>
<li>汤/咖喱味一面 Soup/Curry Weiyi Noodles</li>
<li>炒果条+水果 Fried Keow Tiao + Fruits
</li>
</ul>



<div style={{fontSize:'15px',fontWeight:'bold'}}>Week 2</div>
<ul  style={{fontSize:'15px'}}>
<li>通心粉+水果 Macaroni + Fruits</li>
<li>鸡肉火腿三明治+酸奶饮料 Chicken Sandwich + Yogurt Drink</li>
<li>中式炒饭 Chinese Fried Rice</li>
<li>鸡丝河粉 Shredded Chicken Hor Fun</li>
<li>云吞面+水果 Dried Wan Tan Noodles + Fruits</li>
</ul>

<div style={{fontSize:'15px',fontWeight:'bold'}}>Week 3</div>
<ul  style={{fontSize:'15px'}}>

<li>培根蛋面+水果 Carbonara + Fruits</li>
<li>鸡肉松包+美禄 Chicken Floss Bun + Milo</li>
<li>炸腐乳鸡饭 Fried Fermented Bean Curd Chicken Rice</li>
<li>汤/咖喱鱼丸面 Soup/Curry Fish Ball Noodles</li>
<li>炒米粉+水果 Fried Mi Hun + Fruits</li>
</ul>


<div style={{fontSize:'15px',fontWeight:'bold'}}>Week 4</div>
<ul  style={{fontSize:'15px'}}>
<li>螺旋螺丝粉 + 水果 Fusilli Aglio Olio + Fruits</li>
<li>烤面包+酸奶饮料 Baked Bun + Yogurt Drink</li>
<li>椰浆饭 Nasi Lemak</li>
<li>汤/冬阴功一米 Soup/Tom Yum Yi Mi</li>
<li>板米干+水果 Dried Pan Mi + Fruits</li>
</ul>


<Row className="mt-3">
<Col md={6} lg={6} xl={6} sm={6} xs={6} className="mt-2">
<div className=""style={{fontSize:'15px',color:'#E85C33'}}>RM 350</div>

</Col>
<Col md={6} lg={6} xl={6} sm={6} xs={6}className="justify-end align-items-end text-end">
    <Link to ="/emptyprof">
<Button className=""style={{
      width: '60%',
      backgroundColor: '#7C7C7C', 
      borderColor: '#7C7C7C',    
      color:'white' ,
      fontSize:'14px',
      paddingLeft: '1px',
      paddingRight:'1px'
    }}>
        <img src="plusimg.png"className=""/>
Add
    </Button></Link>
</Col>
      </Row>
</div>



<Row className="mt-3">
<Col md={6} lg={6} xl={6} sm={6} xs={6} className="mt-2">
<div className=""style={{fontSize:'15px',color:'#E85C33'}}>Veg Premium</div>

</Col>
<Col md={6} lg={6} xl={6} sm={6} xs={6}className="justify-end align-items-end text-end">
<img src="mark.png"></img>
</Col>
      </Row> */}


              </Col>



              <Col md={4} lg={4} xl={4}>
                <Row>
                  <Col xl={6} lg={12} md={12} className="text-center">
                    <img src={Image_Url + qr} alt="QR" style={{ width: '140px', height: '140px', padding: '10px', borderRadius: '13.821px', border: '1.152px solid var(--layout-300, #E1E1E1)' }} />
                  </Col>
                  <Col xl={6} lg={12} md={12}>
                    <div className="text-center mt-4 pt-2">
                      <Button className="" style={{
                        width: '60%',
                        backgroundColor: '#292D32',
                        borderColor: '#292D32',
                        color: 'white',
                        fontSize: '15px',
                        paddingLeft: '1px',
                        paddingRight: '1px',
                        borderRadius: '10px'
                      }} onClick={getImg}>
                        {translate('Download')}<img src={process.env.PUBLIC_URL + '/download.png'} className="ps-2" />
                      </Button>
                              {/* <a  className="" href={pdfChildren} target='_blank' download={pdfChildren} style={{
                      width: '60%',
                      backgroundColor: '#292D32',
                      borderColor: '#292D32',
                      color: 'white',
                      fontSize: '15px',
                      paddingLeft: '1px',
                      paddingRight: '1px',
                      borderRadius: '10px'
                    }}> {translate('Download')}<img src={process.env.PUBLIC_URL + '/download.png'} className="ps-2" /></a> */}
                      </div>
            
                    <div className="text-center mt-3">
                      <Button className="" style={{
                        width: '60%',
                        backgroundColor: 'white',
                        borderColor: '#292D32',
                        color: '#292D32',
                        fontSize: '15px',
                        paddingLeft: '1px',
                        paddingRight: '1px',
                        borderRadius: '10px'
                      }} onClick={getShareImg}>
                        {translate('Share')}<img src={process.env.PUBLIC_URL + '/share.png'} className="ps-2" />
                      </Button></div>
                  </Col>
                </Row>
                <Row>

                  <Container className="mt-3">
                    <Row className="calendar-header">

                      <Col className="text-center">

                        <Row>
                          <Col lg={2} xl={2} md={2} sm={2} xs={2}>
                            <Button variant="link" onClick={handlePrevMonth} className="arrow-button">
                              <img src={process.env.PUBLIC_URL + '/prevbtn.png'} />
                            </Button>
                          </Col>

                          <Col lg={8} xl={8} md={8} sm={8} xs={8} >
                            {currentDate.toLocaleString('default', { month: 'long' })}
                            <div className="pb-2" style={{ color: '#8F9BB3', fontSize: '17px', fontWeight: '500' }}>    {currentDate.getFullYear()}</div>

                          </Col>

                          <Col lg={2} xl={2} md={2} sm={2} xs={2}>
                            <Button variant="link" onClick={handleNextMonth} className="arrow-button">
                              <img src={process.env.PUBLIC_URL + '/nextbtn.png'} className="" />
                            </Button>
                          </Col>

                        </Row>

                      </Col>


                    </Row>
                    <Row className="calendar-days" style={{ color: '#8F9BB3' }}>
                      {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day, index) => (
                        <Col key={index} className={`text-center pe-0 me-0 day-label ${day === 'Sun' ? 'sunday' : ''}`} >
                          {day}

                        </Col>
                      ))}
                    </Row>
                    <Row className="calendar-grid">
                      {generateCalendarDays()}
                    </Row>



                  </Container>
                </Row>



              </Col>





            </Row>

            <Modal dialogClassName="modal-dialog-centered" centered show={showModal} onHide={handleCloseModal}  >
              <Modal.Header closeButton>
                <Modal.Title style={{ fontSize: '16px', border: 'none' }} className="">{translate('Edit Child')}</Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ fontSize: '15px' }}>
                <div className="mb-3">
                  <label htmlFor="profileUpload" className="profile-uploader">
                    <input type="file" id="profileUpload" accept="image/*" style={{ display: 'none' }} onChange={handleProfileImageChange} />
                    <div className="profile-image-container position-relative">
                      {profileImage ? (
                        <img src={profileImage} alt="Profile" className="profile-image rounded-circle" style={{ width: '65px', height: '65px' }} />
                      ) : (
                        <div className="profile-placeholder rounded-circle" style={{ width: '65px', height: '65px' }}>
                          <img src={imageChildren} alt="profileImage" style={{ width: '65px', height: '65px', borderRadius: '56px' }} />
                        </div>
                      )}
                      <span className="position-absolute bottom-0 end-0" style={{ top: '60%', left: '60%' }}>
                        <img src={process.env.PUBLIC_URL + '/pencil.png'} style={{ cursor: 'pointer', backgroundColor: 'transparent', borderRadius: '50%', }} />
                      </span>
                    </div>
                  </label>
                </div>

                <Form>
                  <Form.Group controlId="formName" className="mb-3">
                    <Form.Label>{translate('Name')}</Form.Label>
                    <Form.Control type="text" className={(verifyname ? false : 'errorField')} placeholder={translate('Name')} onChange={(e) => checkname(e)}
                      value={name} />
                  </Form.Group>
                  {
                    verifyname ? false : <p style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>{translate('Enter Full Name')}</p>
                  }

                  {/* <Form.Group controlId="formEmail" className="mb-3">
              <Form.Label>{translate('IC No')}</Form.Label>
              <Form.Control type="email"  className={(verifyIoc ? false : 'errorField')} placeholder="" onChange={(e)=>checkIoc(e)}
                    value={ioc} />
            </Form.Group>
            {
              verifyIoc  ? false : <p style={{color:'red',fontSize:'12px',marginTop:'5px'}}>{translate('Enter Full IC No')}</p> 
            } */}

                  <Form.Group controlId="formDropdown1" className="mb-3">
                    <Form.Label>{translate('Class')}</Form.Label>
                    <div className="input-group">
                      <select className={content} id="inputGroupSelect01" style={{
                        borderRadius: '8px',
                        padding: '8px',
                        border: '1px solid #ccc'

                      }} onChange={(e) => checkStudentGrade(e)}
                        value={studentGrade}>
                        <option key="">{translate('Select Class')}</option>
                        {grade.map(item => (
                          <option key={item.GradeID} value={item.GradeID} selected={item.GradeName == studentGrade}>{item.GradeName}</option>
                        ))}

                      </select>

                    </div>
                  </Form.Group>
                  {
                    verifyStudentGrade ? false : <p style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>{translate('Select Class')}</p>
                  }

                  <Form.Group controlId="formName" className="mb-3">
                    <Form.Label>{translate('School')}</Form.Label>
                    <Form.Control type="text" placeholder="" style={{
                      background: "whitesmoke"
                    }} value={childrenDetail.schoolName} readonly />
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>

                {button == true ? (
                  <Button style={{
                    width: '100%',
                    backgroundColor: '#E85C33',
                    borderColor: '#E85C33',
                  }}>
                    <Spinner animation="border" role="status">
                    </Spinner>
                  </Button>
                ) : (
                  <Button onClick={() => {
                    const isConfirmed = window.confirm("Are you sure you want to update? If Updated your QR will be changed.");
                    if (isConfirmed) {
                        addChildren(); // Call the function if the user confirms
                    }
                }} style={{
                    width: '100%',
                    backgroundColor: '#E85C33',
                    borderColor: '#E85C33',
                  }}>
                    {translate('Save Changes')}
                  </Button>
                )}

              </Modal.Footer>
            </Modal>

            <Modal dialogClassName="modal-dialog-centered"
              centered
              show={showSelectChildModal}
              onHide={() => setShowSelectChildModal(false)}

            >
              <div className="mb-5 px-3">
                <div className="text-center mt-5"><img src={process.env.PUBLIC_URL + '/save.png'}></img></div>
                <div className="text-center mt-3 " style={{ fontSize: '20px', fontWeight: 'bold' }}>{translate('Delete Child')}</div>
                <div className="text-center my-3" style={{ color: '#6B6B6B' }}>{translate('Are You Sure,You want to delete Child,Once action is performed it cant undone it')}</div>
                <Row>
                  <Col xl={6} lg={6} md={6} sm={6} xs={6}><Button style={{ background: 'var(--layout-100, #F9F9F9)', color: 'black', border: 'unset', float: 'right' }} onClick={handleSelectChildHide}>{translate('Cancel')}</Button></Col>
                  <Col xl={6} lg={6} md={6} sm={6} xs={6}><Button style={{ background: 'var(--buttons-red-700, #C84242)', color: 'white', border: 'unset' }} onClick={deleteAll} >{translate('Delete')}</Button></Col>
                </Row>
              </div>



            </Modal>
            <Modal dialogClassName="modal-dialog-centered"
              centered
              show={show}
              onHide={() => setShow(false)}

            >
              <div className="my-4">
                <div className="text-center mt-5"><img src={process.env.PUBLIC_URL + '/save.png'}></img></div>
                <div className="text-center mt-3 " style={{ fontSize: '20px', fontWeight: 'bold' }}>{translate('Child Removed Successfully')}</div>
                <div className="text-center my-3" style={{ color: '#6B6B6B' }}>{translate('The Child has been successfully removed from your account')}</div>
              </div>



            </Modal>


            <Modal show={showRenewInfo} onHide={() => setShowRenewInfo(false)} dialogClassName="modal-dialog-centered"
              centered>

              <Modal.Header closeButton>
                <Modal.Title className="" style={{ fontSize: '17px' }} >
                  {/* {childrenData && childrenData.Menus && childrenData.Menus.length > 0 ? <>
                    {childrenData.Menus[infoInd]?.MenuTypeEnglish}
                  </> : null} */}
                  {translate('Renew')}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="modal-body-scrollable" style={{ maxHeight: '60vh', overflowY: 'auto', overflowX: 'hidden' }} >
                <div className='mt-3'>
                  {renewData?.map((val, ind) => {
                    return (

                      <div>
                        <p style={{ fontSize: '17px' }}>{val.result}</p>
                        <p className='ms-2' style={{ fontSize: '15px' }}>{val.message}</p>
                        <hr />

                      </div>
                    )
                  })}

                  <div className='text-end'>
                    <Button style={{
                      backgroundColor: '#E85C33',
                      borderColor: '#E85C33',
                      // maxHeight: '40px'
                    }}
                      onClick={() => { renew1(currOrdrId) }}
                    >Confirm</Button>
                  </div>

                </div>

              </Modal.Body>

            </Modal>


            <Modal show={showMenuDetModal} onHide={() => setMenuDetShowModal(false)} dialogClassName="modal-dialog-centered"
              centered>
              <Modal.Header closeButton>
                <Modal.Title className="" style={{ fontSize: '17px' }} dangerouslySetInnerHTML={{ __html: modalTitle }}></Modal.Title>
              </Modal.Header>
              <Modal.Body className="modal-body-scrollable" style={{ maxHeight: '60vh', overflowY: 'auto', overflowX: 'hidden' }} >

                <div dangerouslySetInnerHTML={{ __html: modalRemark }} />
                <hr style={{ borderColor: '#ccc' }} />
                <div dangerouslySetInnerHTML={{ __html: modalDesciption }} />
                <hr style={{ borderColor: '#ccc' }} />
                <div dangerouslySetInnerHTML={{ __html: notes }} />


                <Row>

                </Row>


              </Modal.Body>

            </Modal>


          </Container>


        </>
      )
      }
    </div >
  );

}


