import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';


import React, { useState } from "react";
import Home from './components/Home';
import Children from './components/Children'
import Cart from './components/Cart';
import Account from './components/Account'
import History from './components/History'
import Payment from './components/Payment'
import Calendar from './components/Calendar'
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Welcome from './components/Welcome'
import Register from './components/Register'
import Otpfield from './components/Otpfield'
import Otpform from './components/Otpform'
import Pwreset from './components/Pwreset'
import Security from './components/Security'
import Newpw from './components/Newpw'
import Order from './components/Order'
import Qrcalendar from './components/Qrcalendar'
import Emptyprof from './components/Emptyprof'
import Neworder from './components/Neworder'
import { LanguageProvider } from './components/LanguageContext';
import Terms from './components/Terms'
import Notfound from './components/Notfound'
import Notification from './components/Notification';
import { Navbar } from 'react-bootstrap';
import Ewallet from './components/Ewallet';
import Menus from './components/menus';
// import EWallet from './components/EWalletOLD';


function App() {
  return (
    <>
      <Navbar />
      <BrowserRouter>
        <LanguageProvider>
          <Routes>

            <Route path="/" element={<Home />} />
            <Route path="/children" element={<Children />} />
            <Route path="/cart" element={<Cart />} />
            <Route path='/account' element={<Account />} />
            <Route path='/history' element={<History />} />
            <Route path='/payment/:id' element={<Payment />} />
            <Route path='/calendar/:childrenId/:id' element={<Calendar />} />
            <Route path='/welcome' element={<Welcome />} />
            <Route path='/register' element={<Register />} />
            <Route path='/otpfield' element={<Otpfield />} />
            <Route path='/otpform' element={<Otpform />} />
            <Route path='/pwreset' element={<Pwreset />} />
            <Route path='/security' element={<Security />} />
            <Route path='/newpw' element={<Newpw />} />
            {/* <Route path='/order' element={<Neworder />} /> */}
            <Route path='/order' element={<Menus />} />
            <Route path='/qrcalendar/:id' element={<Qrcalendar />} />
            <Route path='/emptyprof' element={<Emptyprof />} />
            <Route path='/terms' element={<Terms />} />
            <Route path='/notifications' element={<Notification />} />
            <Route path='/ewallet' element={<Ewallet />} />

            <Route path="*" element={<Notfound />} />
            <Route component={Notfound} />


          </Routes>
        </LanguageProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
