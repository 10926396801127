import React, { useEffect, useState } from 'react';
import './custom.css'
import MyNavbar from './Navbar'
import { Container } from 'react-bootstrap'
import { Row } from 'react-bootstrap'
import { Col } from 'react-bootstrap'
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Base_Url } from './globalvariable/globe';
import { Image_Url } from './globalvariable/globe';
import { useTranslation } from './LanguageContext';
import Loaderbtn from './Loaderbtn';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from './Loader';


export default function MyChildren() {
  const [loading, setLoading] = useState(false);

  const [parentId, setLocalStorageValue] = useState('');
  const [school, setSchool] = useState('');
  const [schoolChnse, setSchoolChnse] = useState('');
  const { selectedLanguage } = useTranslation();
  const [grade, setGrade] = useState([]);
  const [detail, setDetail] = useState([]);
  const [content, setContent] = useState('custom-select w-100  bg-white border-gray-800');
  const [schoolId, setSchoolId] = useState('');
  const { translate, changeLanguage } = useTranslation();
  const [button, SetButton] = useState(false);
  const [noChld, setNoChild] = useState(false)

  const schoolNme=selectedLanguage === 'en'?school:schoolChnse
  const handleLanguageChange = (lang) => {
    changeLanguage(lang);
  }
  let navigate = useNavigate()

  const getdetails = async () => {
    setLoading(true)
    const parentIDString = await localStorage.getItem('hnhpapauserId');
    if (parentIDString) {
      let bodyFormData = new FormData()
      bodyFormData.append('TeacherID', parentIDString)
      let responce = await axios({
        method: 'post',
        url: Base_Url + '/childrensgrade',
        data: bodyFormData,
        dataType: 'json',
        headers: { "Content-Type": "multipart/form-data" }
      })
        .then(async (res) => {
          if (res.data.status === true) {
            setSchool(res.data.result[0].SchoolNameEnglish)
            setSchoolChnse(res.data.result[0].SchoolNameChinese)
            setSchoolId(res.data.result[0].SchoolNameEnglish)
            setGrade(res.data.result)
          }
        })
        .catch((err) => {
          console.log('Error fetching data:', err);
          // setLoading(false)
        });
    } else {
      navigate('/welcome')
    }
  }

  const successToast = (e) => {
    toast.dismiss();
    toast.success(e);
  };
  const errorToast = (e) => {
    toast.dismiss();
    toast.error(e);
  };
  const schools = async () => {
    const parentIDString = await localStorage.getItem('hnhpapauserId');
    if (parentIDString) {
      let bodyFormData = new FormData()
      bodyFormData.append('TeacherID', parentIDString)
      let responce = await axios({
        method: 'post',
        url: Base_Url + '/home',
        data: bodyFormData,
        dataType: 'json',
        headers: { "Content-Type": "multipart/form-data" }
      })
        .then(async (res) => {
          if (res.data.status === true) {
           

            setSchoolId(res.data.result.SchoolID)
            getChildrenDetails(res.data.result.SchoolID);

            localStorage.setItem('hnhpapaSchoolId', res.data.result.SchoolID);
            // setLoading(false)
          }
        })
        .catch((err) => {
          // setLoading(false)

          console.log('Error fetching data:', err);
        });
    } else {
      navigate('/welcome')
    }
  }
  const getChildrenDetails = async (id) => {
    const parentIDString = await localStorage.getItem('hnhpapauserId');
    if (parentIDString) {
      let bodyFormData = new FormData()
      bodyFormData.append('ParentID', parentIDString)
      bodyFormData.append('ParentSchoolID', id)
      let responce = await axios({
        method: 'post',
        url: Base_Url + '/listchildrens',
        data: bodyFormData,
        dataType: 'json',
        headers: { "Content-Type": "multipart/form-data" }
      })
        .then(async (res) => {
          if (res.data.status === true) {
            setDetail(res.data.result)
            setLoading(false)
            setNoChild(false)

            // if(res.data.result)
          }
          else{
            setLoading(false)
            if(res.data){
              setNoChild(true)

            }
          }
        })
        .catch((err) => {
          // setLoading(false)
          console.log('Error fetching data:', err);

        });
    } else {
      navigate('/welcome')
    }
  }

  useEffect(() => {
    getdetails();
    schools();
  }, [])
  const [name, setname] = useState('')
  const [verifyname, setVerifyname] = useState(true)
  const [ioc, setIoc] = useState('');
  const [verifyIoc, setVerifyIoc] = useState(true)
  const [studentGrade, setStudentGrade] = useState('');
  const [verifyStudentGrade, setVerifyStudentGrade] = useState(true)

  const [profileImage, setProfileImage] = useState(null);
  const [image, setImage] = useState('');

  // Handle profile image selection
  const handleProfileImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file.type;
      if (fileType === 'image/jpeg' || fileType === 'image/png') {
        const reader = new FileReader();
        reader.onload = (event) => {
          setProfileImage(event.target.result);
        };
        reader.readAsDataURL(file);
        setImage(file);
      } else {
        alert('Please select a valid image file.');
        // Clear the input field if an invalid file is selected
        e.target.value = null;
      }
    }
  };
  const [showModal, setShowModal] = useState(false);
  const checkname = (e) => {
    setname(e.target.value);
    setVerifyname(true);
    // valid(true)
  }
  const checkIoc = (e) => {
    setIoc(e.target.value);
    setVerifyIoc(true);
    // valid(true)
  }
  const checkStudentGrade = (e) => {
    setStudentGrade(e.target.value);
    setVerifyStudentGrade(true);
    setContent('custom-select w-100  bg-white border-gray-800')
    // valid(true)
  }
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleShowModal = () => {
    setShowModal(true);
  };
  const boxStyle = {

    backgroundColor: '#EFEFEF',
    borderRadius: '12px',
    padding: '12px'


  };
  const schoolName = {
    fontSize: "15px"
  };
  const placeColor = {
    fontSize: "15px"
  };
  const buttoncolor = {
    backgroundColor: '#F17C38',
    border: 'none'
  }

  const addChildren = async () => {

    if (name == "" || studentGrade == "") {
      if (name == "") {
        setVerifyname(false)
      }
      // if(ioc=="")
      // {
      //   setVerifyIoc(false)
      // }
      if (studentGrade == "") {
        setVerifyStudentGrade(false)
        setContent('errorField custom-select w-100  bg-white border-gray-800')
      }
    } else {
      SetButton(true);
      const userId = await localStorage.getItem('hnhpapauserId');
      const formData = new FormData();
      formData.append('ParentID', userId);
      formData.append('ChildrenName', name);
      // formData.append('ChildrenICNumber', ioc);
      formData.append('ChildrenGrade', studentGrade);
      formData.append('ChildrenImage', image);
      let response = await axios({
        method: 'post',
        url: `${Base_Url}/addchildrens`,
        data: formData,
        dataType: 'json',
        headers: { "Content-Type": "multipart/form-data" }
      })
        .then((res) => {
          console.log('response', res);
          if (res.data.status == true) {
            // navigate('/children')
            SetButton(false);
            successToast(`${translate('Children Added Successfully')} `);
            setTimeout(() => {
              window.location.reload()
            }, 1000);


          } else {
            // valid(false)
            SetButton(false);
            errorToast(res.data.errors)
            SetButton(false);

          }
        }).catch((error) => {
          console.error('Error', error)
          SetButton(false);
        })

    }
  }

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>

          <ToastContainer />

          <Container>
            <MyNavbar />

            <div className="my-4">

              <Row className=" ">
                <Col xs={5} sm={7} md={5} lg={3} xl={3}>
                  <Link to="/" className="link-no-underline">
                    <img src="leftarrow.png" alt="School Logo" className="pe-1" /><span className="" style={{ fontSize: '17px' }}> {translate('Children list')}</span>
                  </Link>
                </Col>

                <Col xs={7} sm={5} md={7} lg={9} xl={9} className="text-end">
                  <Button style={buttoncolor} onClick={handleShowModal}>
                    <img src="plus.png" /><span className="ps-2" style={{ fontSize: '15px' }}> {translate('Add Child')}</span>
                  </Button>
                </Col>
              </Row>
            </div>



            <div>
              {detail && detail.length > 0 ? (detail.map((item, index) => (
                // {detail.map((item, index) => (
                <div key={index} style={boxStyle} className="border border-gray-100 my-4 py-3 px-3 mb-1">
                  <Link to={`/qrcalendar/${item.ChildrenID}`} className="link-no-underline">
                    <Row className="flex align-items-center">
                      <Col xs={2} sm={3} md={2} lg={1} xl={1} className="text-end">
                        <img src={Image_Url + item.ChildrenImage} alt="School Logo" style={{ width: '50px', height: '50px', borderRadius: '30px' }} />
                      </Col>
                      <Col xs={6} sm={6} md={8} lg={9} xl={10}>
                        <div className="font-weight-bold text-black">{item.ChildrenName}</div>
                        <div className="placecolor text-muted color-white">{item.ChildrenGrade}</div>
                      </Col>
                      <Col xs={4} sm={3} md={2} lg={2} xl={1}>
                        <div className="">                  {translate('Active')}
                          <span className="ms-1"><img src="active.png" alt="Active" /></span></div>
                      </Col>
                    </Row>
                  </Link>
                </div>
              ))) : (
                !loading && noChld ?
                  <div className="container">
                    <div className="row ">
                      <div className="col-md-6">
                        <div style={boxStyle} className=" border border-gray-100  w-25 py-2 px-3">
                          <p style={{ color: '#E85C33' }}>{translate('Please Add Children')}</p>

                        </div>
                      </div>
                    </div>
                  </div> : null
              )}
            </div>


            <Modal dialogClassName="modal-dialog-centered"
              centered show={showModal} onHide={handleCloseModal}  >
              <Modal.Header closeButton>
                <Modal.Title style={{ fontSize: '16px', border: 'none' }} className="">{translate('Add Child')}</Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ fontSize: '15px' }}>

                <div className="mb-3">
                  <label htmlFor="profileUpload" className="profile-uploader">
                    <input
                      type="file"
                      id="profileUpload"
                      accept="image/*"
                      style={{ display: 'none' }}
                      onChange={handleProfileImageChange}

                    />
                    <div className="profile-image-container position-relative">
                      {profileImage ? (
                        <img src={profileImage} alt="Profile" className="profile-image rounded-circle" style={{ width: '65px', height: '65px' }} />
                      ) : (
                        <div className="profile-placeholder rounded-circle" style={{ width: '65px', height: '65px' }}>
                          <img src={process.env.PUBLIC_URL + '/profile-icon-9.png'} alt="profileImage" style={{ width: '65px', height: '65px' }} />
                        </div>
                      )}
                      <span className="position-absolute bottom-0 end-0" style={{ top: '60%', left: '60%' }}>

                        <img src="pencil.png" style={{ cursor: 'pointer', backgroundColor: 'transparent', borderRadius: '50%', }} />
                      </span>
                    </div>
                  </label>
                </div>

                <Form>
                  <Form.Group controlId="formName" className="mb-3">
                    <Form.Label>{translate('Name')}</Form.Label>
                    <Form.Control type="text" className={(verifyname ? false : 'errorField')} placeholder={translate('Name')} onChange={(e) => checkname(e)}
                      value={name} />
                  </Form.Group>
                  {
                    verifyname ? false : <p style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>{translate('Enter Full Name')}</p>
                  }

                  {/* <Form.Group controlId="formEmail" className="mb-3">
              <Form.Label>{translate('IC No')}</Form.Label>
              <Form.Control type="email"  className={(verifyIoc ? false : 'errorField')} placeholder="" onChange={(e)=>checkIoc(e)}
                    value={ioc} />
            </Form.Group>
            {
              verifyIoc  ? false : <p style={{color:'red',fontSize:'12px',marginTop:'5px'}}>{translate('Enter Full IC No')}</p> 
            } */}



                  <Form.Group controlId="formDropdown1" className="mb-3">
                    <Form.Label>{translate('Class')}</Form.Label>
                    <div className="input-group">
                      <select className={content} id="inputGroupSelect01" style={{
                        borderRadius: '8px',
                        padding: '8px',
                        border: '1px solid #ccc'

                      }} onChange={(e) => checkStudentGrade(e)}
                        value={studentGrade}>
                        <option key="">{translate('Select Class')}</option>
                        {grade.map(item => (
                          <option key={item.GradeID} value={item.GradeID}>{item.GradeName}</option>
                        ))}
                        {/* <option defaultValue>III</option>
            <option>IV</option>
            <option>V</option>
            <option>VI</option>
            <option>VII</option>
            <option>VIII</option> */}


                      </select>

                    </div>
                  </Form.Group>
                  {
                    verifyStudentGrade ? false : <p style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>{translate('Select Class')}</p>
                  }



                  {/* <Form.Group controlId="formDropdown1" className="mb-3">
        <Form.Label>Section</Form.Label>
        <div className="input-group">
          <select className=" custom-select w-100  bg-white border-gray-800" id="inputGroupSelect01" style={{
        borderRadius: '8px',  
        padding: '8px',   
        border: '1px solid #ccc'     
      }}>
            <option defaultValue>A2</option>
            <option>A1</option>
            <option>A3</option>
            <option>B1</option>
            <option>B2</option>
            <option>B3</option>


          </select>
         
        </div>
      </Form.Group> */}

                  <Form.Group controlId="formName" className="mb-3">
                    <Form.Label>{translate('School')}</Form.Label>
                    <Form.Control type="text" placeholder="St.Mary Goretti Hr.Sec School" style={{
                      background: "whitesmoke"
                    }} value={schoolNme} readonly />
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>

                {button == true ? (
                  <Loaderbtn />
                ) : (
                  <Button onClick={addChildren} style={{
                    width: '100%',
                    backgroundColor: '#E85C33',
                    borderColor: '#E85C33',
                  }}>
                    {translate('Create Child')}
                  </Button>
                )}


              </Modal.Footer>
            </Modal>

          </Container>
        </>
      )}
    </div>

  );
}