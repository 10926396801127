import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { useTranslation, LanguageProvider } from './LanguageContext';
import { Base_Url, Image_Url } from './globalvariable/globe';
import axios from 'axios';


const MyNavbar = () => {
  const keyExists = localStorage.getItem('hnhpapacartCount') !== null;
  const cartCount = keyExists ? localStorage.getItem('hnhpapacartCount') : 0;
  const keyExists1 = localStorage.getItem('hnhpapaparentimage') !== null;
  const profileImage = keyExists1 ? localStorage.getItem('hnhpapaparentimage') : false;
  if (profileImage == null) {
    profileImage = false;
  }
  const brandStyle = {
    color: '#303A5B',
    fontWeight: 'bold',
    fontSize: '25px'
  };
  const appStyle = {
    color: '#F17C38',
    fontWeight: 'bold',
    fontSize: '25px'
  };
  const cart = {
    color: '#F17C38'
  };
  const [image, setImage] = useState('');


  const { translate, changeLanguage, selectedLanguage,notiCnt } = useTranslation();

  const handleLanguageChange = async (lang) => {
    const ParentId = localStorage.getItem('hnhpapauserId');

    let language = lang === "en" ? 'english' : 'chinese';

    const setLang = {
      ParentID: ParentId,
      language: language
    }
    await axios.post(`${Base_Url}/saveLanguage`, setLang)
      .then((res) => {
        console.log(res);
        localStorage.setItem('ParentLanguage', language);

        changeLanguage(lang);
      }).catch((err) => {
        console.log("Error :", err);
      })
    // changeLanguage(lang);
  };

  const languageTitles = {
    en: 'English',
    es: 'Chinese',
    ma: 'Malaysian'
  };

  const dropdownTitle = languageTitles[selectedLanguage] || '';
  const jsonData = localStorage.getItem('schoolData');
  const ParentLanguage = localStorage.getItem('ParentLanguage');

  useEffect(() => {
    const ParentLanguage = localStorage.getItem('ParentLanguage');
    if (ParentLanguage) {
      let language = ParentLanguage === "english" ? 'en' : 'es';

      changeLanguage(language);

    }


  }, [])

  return (
    <>
      <Navbar className="bg-body-tertiary mt-3">
        <Container>
          <Link to="/" className="link-no-underline">
            <Navbar.Brand href="#home" style={brandStyle}>
              <img src={process.env.PUBLIC_URL + '/Icon-512.png'} style={{ width: '80px', height: '80px' }} />
              {/* {translate('Canteen')}
   <span style={appStyle}>                  {translate('App')}
  </span> */}
            </Navbar.Brand>
          </Link>
          <Navbar.Toggle className="" aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto"></Nav>
            <Nav>
              <NavDropdown className="" style={{marginTop:'13px'}} title={translate(dropdownTitle)} >
                <NavDropdown.Item onClick={() => ParentLanguage ? handleLanguageChange('en') : changeLanguage('en')}>English</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={() => ParentLanguage ? handleLanguageChange('es') : changeLanguage('es')}>中文</NavDropdown.Item>
                {/* <NavDropdown.Divider />
                <NavDropdown.Item onClick={() => handleLanguageChange('ma')}>Malaysian</NavDropdown.Item> */}
              </NavDropdown>
              <Link to="/notifications" className="link-no-underline pt-2 pe-3" >
                  <img src={process.env.PUBLIC_URL + '/notify.png'} alt="Notification" style={{marginTop:'15px'}} className=" ps-1" />
                
                  {notiCnt > 0 ? <span style={{
                    marginTop: '-33px', display: 'block', marginLeft: '-2px', color: 'white', padding: '-11px',
                    borderRadius: '24px', textAlign: 'center', background: 'red', width: '16px', height: '18px', fontSize: '12px'
                  }}>
                    {notiCnt}
                  </span> : null}

                </Link>
              <Link to="/cart" className="link-no-underline pt-4" href="/cart">
                <span>
                  <img style={{height:'21px',color:'#F17C38'}} src={process.env.PUBLIC_URL + '/Cart.png'} className="mb-1 " alt="Cart" />
                </span>
                <span style={cart} className="">
                  {translate('Cart')}
                </span>
                {cartCount > 0 ?
                  <span style={{ marginTop: '-37px', display: 'block', marginLeft: '-10px', color: 'white', padding: '-11px', borderRadius: '24px', textAlign: 'center', background: 'red', width: '16px', height: '18px', fontSize: '12px' }}>{cartCount > 0 ? cartCount : ''}</span> : ''}
              </Link>

              <Link to="/account" className="mt-2 ms-2 link-no-underline" >

                {profileImage ? (
                  <img src={Image_Url + profileImage} alt="Profile" className="profile-image rounded-circle" style={{
                    width: '50px',
                    height: '50px',
                    borderColor: '#E85C33',  // Border color
                    borderWidth: '2px',   // Border width
                    borderStyle: 'solid', // Border style
                    borderRadius: '50%',  // Rounded border
                  }} />
                ) : (
                  <div className="profile-placeholder rounded-circle" style={{ width: '65px', height: '65px' }}>
                    <img src={process.env.PUBLIC_URL + "default1.jpg"} alt="profileImage" style={{
                      width: '65px',
                      height: '65px',
                      borderColor: '#E85C33',
                      borderWidth: '2px',
                      borderStyle: 'solid',
                      borderRadius: '50%',
                    }} />
                  </div>
                )}

              </Link>

            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default MyNavbar;