import React from 'react'
import './custom.css'
import { useState, useEffect } from 'react'

import { Container } from 'react-bootstrap'
import { Row } from 'react-bootstrap'
import { Col } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import { Base_Url } from './globalvariable/globe';
import { Image_Url } from './globalvariable/globe';
import axios from 'axios';

import { useTranslation } from './LanguageContext';
export default function MyHomebox() {
  let navigate = useNavigate()
  const [school, setSchool] = useState([])
  const [schoolc, setSchoolc] = useState([])

  const [place, setPlace] = useState([])
  const [placeC, setPlaceC] = useState([])
  const [schoolLogo, setSchoolLogo] = useState('')
  const [schoolColor, setSchoolColor] = useState(null)
  const [textColor, setTextColor] = useState(null)
  const { selectedLanguage } = useTranslation();
  const [walAmt, setWalAmt] = useState('')

  const getdetails = async () => {
    const parentIDString = await localStorage.getItem('hnhpapauserId');
    if (parentIDString) {
      let bodyFormData = new FormData()
      bodyFormData.append('TeacherID', parentIDString)
      let responce = await axios({
        method: 'post',
        url: Base_Url + '/home',
        data: bodyFormData,
        dataType: 'json',
        headers: { "Content-Type": "multipart/form-data" }
      })
        .then(async (res) => {
          if (res.data.status === true) {
            setSchool(res.data.result.SchoolNameEnglish);
            setSchoolc(res.data.result.SchoolNameChinese);
            setPlace(res.data.result.SchoolAddressEnglish);
            setPlaceC(res.data.result.SchoolAddressChinese);
            setSchoolLogo(res.data.result.SchoolLogo);
            setSchoolColor(res.data.result.SchoolColor);
            setTextColor(res.data.result.SchoolTextColor);
            setWalAmt(res.data.result.walletbalance);
          }
        })
        .catch((err) => {
          console.log('Error fetching data:', err);
        });
    } else {
      navigate('/welcome')
    }
  }
  useEffect(() => {
    getdetails();
  }, [])
  if (schoolColor == null) {
    var backcolor = '#4BBD9A';
  } else {
    var backcolor = schoolColor;
  }
  if (textColor == null) {
    var backtextcolor = 'black';
  } else {
    var backtextcolor = textColor;
  }
  const boxStyle = {

    backgroundColor: backcolor,
    padding: '2px',
    borderRadius: '15px',

  };
  const schoolName = {
    fontSize: "15px",
    color: backtextcolor
  };
  const placeColor = {
    fontSize: "15px",
    color: backtextcolor

  };

  return (
    <>

      {/* <Container> */}


      <div style={boxStyle} className="border border-gray-100 my-4 py-3 px-3">
        <Row className="align-items-center">
          <Col xs={3} sm={2} md={2} lg={1} xl={1}>
            <div>
              <img
                src={Image_Url + schoolLogo}
                style={{ width: '100%', maxWidth: '50px', height: 'auto' }} // Responsive image
                alt="School Logo"
              />
            </div>
          </Col>
          <Col xs={9} sm={7} md={8} lg={9} xl={9}>
            {selectedLanguage === 'en' && (
              <div style={schoolName} className="font-weight-bold">{school}</div>
            )}
            {selectedLanguage === 'es' && (
              <div style={schoolName} className="font-weight-bold">{schoolc}</div>
            )}
            {selectedLanguage === 'en' && (
              <div style={placeColor} className="placecolor">{place}</div>
            )}
            {selectedLanguage === 'es' && (
              <div style={placeColor} className="placecolor">{placeC}</div>
            )}
          </Col>
          <Col xs={12} sm={3} md={2} lg={2} xl={2} className="text-sm-right text-md-right">
            <div style={{ ...placeColor, fontSize: '19px' }} className="placecolor text-end">RM {walAmt}</div>
          </Col>

        </Row>
      </div>



      {/* <div style={boxStyle} className="border border-gray-100 my-4 py-3 px-3">
        <Row className="flex align-items-center ">
          <Col xs={3} sm={3} md={2} lg={1} xl={1}>
            <div>
              <img src={Image_Url + schoolLogo} style={{ width: '50px', height: '50px' }} />
            </div>
          </Col>
          <Col xs={9} sm={9} md={10} lg={11} xl={11} className="">

            {selectedLanguage === 'en' && (
              <div style={schoolName} className="font-weight-bold">{school}</div>
            )}
            {selectedLanguage === 'es' && (
              <div style={schoolName} className="font-weight-bold">{schoolc}</div>
            )}
            {selectedLanguage === 'en' && (
              <div style={placeColor} className="placecolor   ">{place}</div>
            )}
            {selectedLanguage === 'es' && (
              <div style={placeColor} className="placecolor   ">{placeC}</div>
            )}
          </Col>
          <Col>
          <div style={placeColor} className="placecolor   ">{walAmt}</div>
          </Col>
        </Row>
      </div> */}


      {/* </Container> */}
    </>

  );
}