import React, { useEffect, useState } from 'react'
import './custom.css'
import { Button, Carousel, Modal } from 'react-bootstrap';
import { Container } from 'react-bootstrap'
import MyNavbar from './Navbar';
import MyHomebox from './Homebox'
import { Row } from 'react-bootstrap'
import { Col } from 'react-bootstrap'
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Base_Url } from './globalvariable/globe';
import { Image_Url } from './globalvariable/globe';

import { useTranslation, LanguageProvider } from './LanguageContext';
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Loader from './Loader';



export default function Home() {
  let navigate = useNavigate()
  const [banner, setBanner] = useState([])
  const [schoolColor, setSchoolColor] = useState(null)
  const [textColor, setTextColor] = useState(null)
  const [cartCount, setcartCount] = useState(0)
  const [alertData, setAlertData] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [updateAlert, setUpdateAlert] = useState(false)
  const [loading, setLoading] = useState(false)
  // {console.log(banner,"ba1")}


  const tId = '1'
  const getAlertDetails = async () => {
    const teacherIDString = localStorage.getItem('hnhpapauserId');
    if (teacherIDString) {
      let bodyFormData = new FormData()
      bodyFormData.append('TeacherID', teacherIDString)
      let responce = await axios({
        method: 'post',
        url: Base_Url + '/childmenudetailslist',
        data: bodyFormData,
        dataType: 'json',
        headers: { "Content-Type": "multipart/form-data" }
      })
        .then(async (res) => {
          if (res.data.status === true) {
            setAlertData(res.data.result)
            setShowModal(true)
          }
        })
        .catch((err) => {
          console.log('Error fetching data:', err);
        });
    }
  }


  const getdetails = async () => {
    setLoading(true)
    const teacherIDString = await localStorage.getItem('hnhpapauserId');
    if (teacherIDString) {
      let bodyFormData = new FormData()
      bodyFormData.append('TeacherID', teacherIDString)
      let responce = await axios({
        method: 'post',
        url: Base_Url + '/home',
        data: bodyFormData,
        dataType: 'json',
        headers: { "Content-Type": "multipart/form-data" }
      })
        .then(async (res) => {
          if (res.data.status === true) {
            setBanner(res.data.result.banner_images);
            localStorage.setItem('hnhpapaschoolName', res.data.result.SchoolNameEnglish);
            localStorage.setItem('hnhpapateacherimage', res.data.result.teacherImage);
            localStorage.setItem('TeacherLanguage', res.data.result.TeacherLanguage);
            localStorage.setItem('hnhpapateacherwallet', res.data.result.walletbalance);
            setNotification(res.data.result.notificationCount)

            if (res.data.result.IsAlert == 1) {
              getAlertDetails()
            }
            if (res.data.result.alertupdate == 1) {
              toast.warning("Please Edit Your Child's Class ", {
                toastId: tId
              });
            }

            // setTimeout(() => {
            //   setUpdateAlert(false)
            // }, 2000);
          }

        })
        .catch((err) => {
          // setLoading(false)
          console.log('Error fetching data:', err);
        });
    } else {
      navigate('/welcome')
    }
  }

  const getCartCount = async () => {
    const teacherIDString = await localStorage.getItem('hnhpapauserId');
    if (teacherIDString) {
      let bodyFormData = new FormData()
      bodyFormData.append('TeacherID', teacherIDString)
      let responce = await axios({
        method: 'post',
        url: Base_Url + '/cartcount',
        data: bodyFormData,
        dataType: 'json',
        headers: { "Content-Type": "multipart/form-data" }
      })
        .then(async (res) => {

          if (res.data.status === true) {
            localStorage.setItem('hnhpapacartCount', res.data.result.cartItemCount);
            setcartCount(res.data.result.cartItemCount);
            setLoading(false)
          }
        })
        .catch((err) => {
          // setLoading(false)
          console.log('Error fetching data:', err);
        });
    } else {
      navigate('/welcome')
    }
  }
  useEffect(() => {
    getdetails();
    getCartCount();
  }, [])
  const cardone = {
    backgroundColor: '#F64460',
    padding: '20px',
    borderRadius: '15px',
    color: 'white',
    backgroundImage: 'url("children.png")',
    backgroundSize: '150% 150%',

    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  };
  const cardtwo = {
    backgroundColor: '#31A2A8',
    padding: '20px',
    borderRadius: '15px',
    color: 'white',
    backgroundImage: 'url("acc.png")',
    backgroundSize: '150% 150%',

    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  };
  const cardthree = {
    backgroundColor: '#F17C38',
    padding: '20px',
    borderRadius: '15px',
    color: 'white',
    backgroundImage: 'url("orders.png")',
    backgroundSize: '150% 150%',

    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  };
  const cardfour = {
    backgroundColor: '#372250',
    padding: '20px',
    borderRadius: '15px',
    color: 'white',
    backgroundImage: 'url("ordershistory.png")',
    backgroundSize: '150% 150%',

    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  };
  const { translate, changeLanguage, setNotification } = useTranslation();

  const handleLanguageChange = (lang) => {
    changeLanguage(lang);
  }
  return (
    loading ? <Loader />
      : <>
        <ToastContainer limit={1} />
        <Container>
          <MyNavbar />
          <MyHomebox />
          <section id="section1">
            <Carousel>
              {
                banner && banner.length > 0 ? (
                  banner?.map((item, index) => (
                    <Carousel.Item key={index} className="lower-carousel-height">
                      <a href={item.OpenLink} target='_blank'>
                        <img
                          style={{ maxHeight: '420px' }}
                          className="d-block w-100"
                          src={Image_Url + item.imgUrl}
                          alt={`Slide ${index}`}
                        />
                      </a>
                      <Carousel.Caption></Carousel.Caption>
                    </Carousel.Item>
                  ))
                )
                  : (
                    <Carousel.Item className="lower-carousel-height">
                      <img
                        style={{ maxHeight: '420px' }}

                        className="d-block w-100"
                        src={process.env.PUBLIC_URL + '/bannerone.png'}
                        alt=""
                      />
                      <Carousel.Caption></Carousel.Caption>
                    </Carousel.Item>
                  )}
            </Carousel>

          </section>

          <section id="section2">


            <Row className="my-4 h-50">

              <Col xs={6} sm={6} md={3} className="pb-3">
                <Link to="/ewallet" className="link-no-underline">
                  <Card style={cardone} className="py-5 ">
                    <div className="py-3">
                      <div className="d-flex justify-content-center align-items-center ">

                      </div>
                      <Card.Body>
                        <div className="text-center">{translate('E-Wallet')}</div>
                      </Card.Body>
                    </div>
                  </Card>
                </Link>
              </Col>

              <Col xs={6} sm={6} md={3}>
                <Link to="/account" className="link-no-underline">

                  <Card style={cardtwo} className="py-5">
                    <div className="py-3">

                      <div className="d-flex justify-content-center align-items-center">

                      </div>
                      <Card.Body>
                        <div className="text-center">{translate('Account')}</div>
                      </Card.Body>
                    </div>
                  </Card>
                </Link>
              </Col>


              <Col xs={6} sm={6} md={3}>
                <Link to="/order" className="link-no-underline">
                  <Card style={cardthree} className="py-5">
                    <div className="py-3">

                      <div className="d-flex justify-content-center align-items-center">

                      </div>
                      <Card.Body>
                        <div className="text-center">{translate('Menu')}</div>
                      </Card.Body>
                    </div>
                  </Card>
                </Link>
              </Col>


              <Col xs={6} sm={6} md={3}>
                <Link to="/history" className="link-no-underline">
                  <Card style={cardfour} className="py-5">
                    <div className="py-3">
                      <div className="d-flex justify-content-center align-items-center">
                      </div>
                      <Card.Body>
                        <div className="text-center" style={{ whiteSpace: 'nowrap' }}>
                          {translate('Order History')}
                        </div>
                      </Card.Body>
                    </div>
                  </Card>
                </Link>
              </Col>

            </Row>

          </section>
        </Container>
        <Modal dialogClassName="modal-dialog-centered"
          centered
          show={showModal}
          onHide={() => setShowModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ fontSize: '16px', border: 'none' }} className="">{translate('Expires Soon')}</Modal.Title>
          </Modal.Header>
          <div className="my-4">

            {alertData && alertData.length > 0 ? (alertData.map((item, index) => (
              <Row className="mx-3" id={item.ChildrenID}>
                <div style={{ borderRadius: '5px' }} className="border border-gray-100 my-1  py-3 px-3">
                  <Row className="flex align-items-center  ">
                    <Col xs={2} sm={2} md={2} lg={1} xl={1} className="text-end">
                      <img src={Image_Url + item.ChildData.ChildrenImage} className="text-end" alt="School Logo" style={{ width: '44px', height: '44px', borderRadius: '44px' }} />
                    </Col>
                    <Col xs={5} sm={6} md={4} lg={5} xl={9} className="">
                      <div style={{ fontSize: "15px" }} className="font-weight-bold text-black ms-3">{item.ChildData.ChildrenName}</div>
                      {/* <div style={{ fontSize: "15px" }} className="placecolor  text-muted  color-white ">{item.MenuInfo[0].MenuTittleEnglish} </div> */}
                    </Col>
                    {/* <Col xs={5} sm={4} md={6} lg={6} xl={2} className="">
                    <div className="text-end" style={{ fontSize: '15px' }}>{item.OrderDate}</div>
                    <div className="text-end" style={{ fontSize: '15px', color: '#1EAC81' }}>RM {item.subAmount}</div>
                  </Col> */}
                  </Row>
                  <Row>
                    <Col md={9} lg={9} xl={10} sm={9} xs={8} className="mt-2 ">
                      <ul>
                        <li>
                          {item.MenuInfo[0].MenuTittleEnglish}
                        </li>
                        <li>{item.MenuInfo[0].MenuTypeEnglish}</li>
                      </ul>
                    </Col>
                    <div md={3} lg={3} xl={4} sm={3} xs={4} className="justify-content-end align-items-end">
                      <div className="mt-2 text-end" style={{ color: 'red' }}  >{translate('Order expires Soon')}</div>
                    </div>
                  </Row>
                </div>
              </Row>
            ))
            ) : (
              <p>n</p>
            )}
          </div>

        </Modal>
      </>


  );


}
