import React, { useEffect, useState } from 'react'
import './custom.css'
import { Container } from 'react-bootstrap'
import { Row } from 'react-bootstrap'
import { Col } from 'react-bootstrap'
import MyNavbar from './Navbar';
import { ListGroup } from 'react-bootstrap'
import { Form } from 'react-bootstrap';
import { Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Base_Url } from './globalvariable/globe';
import { Image_Url } from './globalvariable/globe';
import { useTranslation } from './LanguageContext';
import Loader from './Loader';
import { ToastContainer, toast } from 'react-toastify';

// import Razorpay from 'razorpay'



export default function Cart() {
  const { translate, changeLanguage } = useTranslation();
  const [itemToDelete, setItemToDelete] = useState(null);
  const [loading, setLoading] = useState(false);
  const { selectedLanguage } = useTranslation();

  const [btnLoading, setBtnLoading] = useState(false);

  const handleLanguageChange = (lang) => {
    changeLanguage(lang);
  }
  const [teacherId, setLocalStorageTeacherId] = useState('');
  const [schoolId, setLocalStorageSchoolId] = useState('');
  const [menuId, setMenuId] = useState(0);

  const [cartLists, setLocalStorageCartList] = useState([]);
  const [subTotal, setSubTotal] = useState('');
  const [totals, setTotal] = useState('');
  const [tax, setTax] = useState('');
  const [gst, setGST] = useState(1)
  const [terms, setTerms] = useState('');
  const [policy, setPolicy] = useState('');
  const [verifyTerms, setVerifyTerms] = useState(true);
  const [verifyPolicy, setVerifyPolicy] = useState(true);
  const [verifyPayMethod, setPayMethod] = useState(true);

  const [isChecked, setIsChecked] = useState(false);
  const [isChecked1, setIsChecked1] = useState(false);
  const keyExists = localStorage.getItem('hnhpapacartCount') !== null;
  const [cartCount, setcartCount] = useState(keyExists ? localStorage.getItem('hnhpapacartCount') : 0);
  const [isModalOpen, setModalOpen] = useState(false);
  const [showMenuInfo, setShowMenuInfo] = useState(false)
  const [paymentMethod, setPaymentMethod] = useState(false)
  const [selectedValue, setSelectedValue] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const walletBalance = localStorage.getItem('hnhpapateacherwallet');

  let param;
  const cartDetail = cartLists?.flatMap(val => val.cartDetails)
  const [infoInd, setInfoInd] = useState({})
  console.log(infoInd, "infoo")
  const checkTerms = () => {
    setIsChecked(!isChecked);
    setVerifyTerms(true)
  }
  const checkPolicy = () => {
    setIsChecked1(!isChecked1);
    setVerifyPolicy(true)
  }



  const [showSelectChildModal, setShowSelectChildModal] = useState(false);
  const [showSelectChildModal1, setShowSelectChildModal1] = useState(false);
  const handleSelectChild = () => {
    setShowSelectChildModal(true);
  }
  const handleSelectChild1 = () => {
    setShowSelectChildModal1(true);
  }
  const handleSelectChildHide = () => {
    setShowSelectChildModal(false);
  }
  const handleSelectChildHide1 = () => {
    setShowSelectChildModal1(false);
  }

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;
    document.body.appendChild(script);
    script.onload = () => {
    };
    return () => {
      // Cleanup: remove the script from the DOM when the component unmounts
      document.body.removeChild(script);
    };
  }, []);
  const dayNamesMapping = {
    Sun: 'Sunday',
    Mon: 'Monday',
    Tue: 'Tuesday',
    Wed: 'Wednesday',
    Thu: 'Thursday',
    Fri: 'Friday',
    Sat: 'Saturday'
  };
  const handlePaymentSubmit = (val, meth) => {
    let amt = val.amount * 100

    var options = {
      // key: "rzp_live_HDJaK8R0U28N2k",
      key: "rzp_test_6rfv0AXZzpXeMu",
      amount: amt,
      currency: val.currency,
      name: "canteen app",
      order_id: val.id,
      description: "for testing purpose",
      handler: function (response) {
        if (response.razorpay_payment_id) {
          handleSelectChild()

          setTimeout(() => {
            handleSelectChildHide()
            navigate('/history')
          }, 3000);
        }

      },
      prefill: {
        name: val.notes.userName,
        email: val.notes.userEmail,
        contact: val.notes.userPhone
      },
      notes: {
        address: ""
      },
      theme: {
        color: "#3399cc"
      },
      modal: {
        ondismiss: function () {
          console.log('Checkout form closed');
          cartList(teacherId, schoolId)
        }
      }
    };

    // amount value is in multiples of 10000 
    options.config = {
      display: {
        blocks: {
          banks: {
            name: 'Pay via Fpx',
            instruments: meth
          },
        },
        sequence: ['block.banks'],
        preferences: {
          show_default_blocks: false,
        },
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.on('payment.failed', function (response) {
      console.log('Payment Failed:', response);
      // Handle failure logic here
      toast.error('Payment Failed! Please try again or choose a different payment method.');
      // setTimeout(() => {
      // }, 3000);

      cartList(teacherId, schoolId)
    });

    paymentObject.on('payment.modal.close', function () {
      console.log('Payment modal closed');
      cartList(teacherId, schoolId)

    });
    setBtnLoading(false)

    paymentObject.open();

  }

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleClose = () => setModalVisible(false);
  const handleConfirm = () => {
    setModalVisible(false);
    navigate('/ewallet'); // Navigate to "/wallet"
  };

  const proceedToPay = async () => {
    console.log({ selectedValue, walletBalance, totals });
    if (isChecked == false || isChecked1 == false || verifyPayMethod == false) {
      if (isChecked == false) {
        setVerifyTerms(false)
      }
      if (isChecked1 == false) {
        setVerifyPolicy(false)
      }
      if (selectedValue == '') {
        setPayMethod(false)
      }


    } if (selectedValue == 1 && parseInt(walletBalance, 10) < parseInt(totals, 10)) {

      // toast.error('Not Enough Wallet Balance')


      setModalVisible(true)

    } else {



      setBtnLoading(true);
      let response = await axios({
        method: 'post',
        url: `${Base_Url}/placeOrders`,
        data: {
          TeacherSchoolID: schoolId,
          TeacherID: teacherId,
          paymentType: selectedValue
        }
      })
        .then((res) => {
          console.log('response', res);
          if (res.data.status == true) {
            setBtnLoading(false);
            getcartCount();
            toast.success('Order Placed Succesfully');
            setTimeout(() => {
              // navigate('/history')
              // handleSelectChild();
              // cartList(teacherId,schoolId)
              getDetails();
            }, 2000);
            if (selectedValue == 2) {
              handlePaymentSubmit(res.data.result.paymentDetails, res.data.result.paymentmethods)
            }

          }
          else {

            alert(res.data.message);
            setBtnLoading(false);
          }
        }).catch((error) => {
          console.error('Error', error)
        })
    }
  }


  const removeCart1 = (param) => {
    setItemToDelete(param);
    handleSelectChild1()
  };
  const removeCart = async () => {
    // param = itemToDelete;
    param = localStorage.getItem('hnhpapauserId')
    let response = await axios({
      method: 'post',
      url: `${Base_Url}/deletecart`,
      data: {
        TeacherID: param,
        MenuID: menuId,
      }
    })
      .then((res) => {
        console.log('response', res);
        if (res.data.status == true) {
          // const updatedItems = cartLists.filter(item => item.ChildrenID !== param);
          // setLocalStorageCartList(updatedItems);
          getDetails();
          getcartCount()
          handleSelectChildHide1()
        } else {
        }
      }).catch((error) => {
        console.error('Error', error)
      })
  };
  const getcartCount = async () => {

    let response = await axios({
      method: 'post',
      url: `${Base_Url}/cartcount`,
      data: {
        TeacherID: teacherId,
      }
    })
      .then((res) => {
        console.log('response', res);

        if (res.data.status == true) {
          setcartCount(localStorage.setItem('hnhpapacartCount', res.data.result.cartItemCount));
        } else {
          alert(res.data.message);
        }
      }).catch((error) => {
        console.error('Error', error)
      })
  }

  const boxStyle = {
    borderRadius: '5px',

  }
  const remove = {
    color: '#E85C33',
    fontSize: '15px',
    cursor: 'pointer',


  }
  const schoolName = {
    fontSize: "15px"

  }
  const placeColor = {
    fontSize: "15px"

  }
  const total = {
    color: '#E85C33',
    fontSize: '17px'

  }
  const order = {
    color: '#303A5B',
    fontSize: '15px',
    fontWeight: 'bold'
  }
  const subtotal = {
    color: '#7C7C7C'

  }
  const check = {
    color: '#303A5B',
    marginTop: '-71px'


  }
  let navigate = useNavigate()
  const getDetails = () => {
    setLoading(true)
    const keyExists = localStorage.getItem('hnhpapauserId') !== null;
    let storedValue;
    let storedValues;
    if (keyExists) {
      storedValue = localStorage.getItem('hnhpapauserId');
      setLocalStorageTeacherId(storedValue);
    } else {
      navigate('/welcome');
    }
    const keyExistsSchool = localStorage.getItem('hnhpapaSchoolId') !== null;
    if (keyExistsSchool) {
      storedValues = localStorage.getItem('hnhpapaSchoolId');
      setLocalStorageSchoolId(storedValues);
    } else {
      navigate('/welcome');
    }

    cartList(storedValue, storedValues)
  }

  const cartList = async (storedValue, storedValues) => {

    let response = await axios({
      method: 'post',
      url: `${Base_Url}/viewcart`,
      data: {
        TeacherID: storedValue,
        TeacherSchoolID: storedValues,
      }
    })
      .then((res) => {
        console.log('response', res);
        if (res.data.status == true) {
          setLoading(false)
          setLocalStorageCartList(res.data.result)

          // setMenuId(res.data.result.cartDetailsByChildren)
          setSubTotal(res.data.subAmount)
          setTotal(res.data.totalAmount)
          setTax(res.data.taxAmount)
          setGST(res.data.gstvalue)
        } else {
        }
      }).catch((error) => {
        console.error('Error', error)
      })
  }
  useEffect(() => {
    getDetails();
  }, [])



  return (
    <div>
      {loading ? (
        <Loader /> // Show the loader when loading is true
      ) : (
        <>
          {/* Your existing code */}

          <Container>
            <MyNavbar />
            <ToastContainer />
            <Col xs={5} sm={7} md={5} lg={3} xl={3} className="mt-4">
              <Link to="/order" className="link-no-underline ">
                <img src="leftarrow.png" alt="School Logo" className="pe-2 " />
                {translate('Cart')}
              </Link>
            </Col>

            <Row className="my-5">
              <Col md={8}>
                {cartLists?.length > 0 ? (
                  cartLists.map((brandItem, brandIndex) => (
                    <Row className="mx-1" id={brandIndex} key={brandIndex}>
                      <div style={boxStyle} className="border border-gray-100 my-1 py-3 px-3">
                        {/* Brand details */}
                        <Row className="flex align-items-center">
                          {/* Brand Logo */}
                          <Col xs={2} sm={2} md={2} lg={1} xl={1} className="text-end">
                            <img
                              src={Image_Url + brandItem.BrandLogo}
                              className="text-end"
                              alt="Brand Logo"
                              style={{ width: '44px', height: '44px', borderRadius: '44px' }}
                            />
                          </Col>
                          {/* Brand Name and Date */}
                          <Col className="text-end">
                            <span className="text-end">Ordered From</span><br />
                            <strong style={schoolName} className="font-weight-bold text-black text-end">
                              {brandItem.BrandName}
                            </strong>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="">
                            <div className="text-end" style={{ fontSize: '15px' }}>
                              {brandItem.date}
                            </div>
                          </Col>
                        </Row>

                        {/* Product details */}
                        <div>
                          <Col className="mt-2">
                            <ul className="text-start" style={{ fontSize: '14px', color: '#7C7C7C', listStyleType: 'none', paddingLeft: 0 }}>
                              {brandItem.products?.map((productItem, productIndex) => (
                                <Row key={productIndex}>
                                  {/* Product title and description */}
                                  <Col xs={6} className="mt-3">
                                    <li style={{ display: "flex", alignItems: "center" }}>
                                      <div
                                        style={{
                                          backgroundColor: productItem.MenuProductType == 1 ? '#ff4500' : '#5bc0de',
                                          color: 'white',
                                          padding: '5px 10px',
                                          borderRadius: '5px',
                                          display: 'inline-block',
                                          marginRight: "10px"
                                        }}
                                      >
                                        {productItem.MenuProductType == 1 ? "Regular" : "Seasonal"}
                                      </div>
                                      <div className="text-black">{productItem.MenuTittleEnglish}</div>
                                    </li>

                                  </Col>

                                  {/* Product amount and cart count with delete button */}
                                  <Col xs={6} className="text-end mt-3 d-flex justify-content-end align-items-center">
                                    <span className="me-3">RM {productItem.amount}</span>
                                    <span className="me-3">x{productItem.CartCount}</span>
                                    <img
                                      onClick={() => { setMenuId(productItem.MenuID); removeCart1(brandItem.BrandID); }}
                                      src={process.env.PUBLIC_URL + '/bin.png'}
                                      alt="bin"
                                      style={{ width: '18px', height: '18px' }}
                                    />
                                  </Col>
                                </Row>
                              ))}
                            </ul>
                          </Col>
                        </div>
                      </div>
                    </Row>
                  ))
                ) : (
                  <p style={{ color: '#E85C33' }}>{translate('No cart details available')}</p>
                )}



              </Col>
              {cartLists && cartLists.length != 0 && (
                <Col md={4}>

                  <div className="mx-1">
                    <div style={order}>{translate('Order Info')}</div>
                    <Row className="mt-2 ">
                      <Col lg={8} sm={8} xs={8} md={7}>
                        <div className="" style={subtotal}>{translate('Subtotal')}</div>
                      </Col>
                      <Col lg={4} sm={4} xs={4} md={5}>
                        <div className="text-end" style={subtotal}>RM {subTotal}</div>
                      </Col>
                    </Row>

                    <Row className="mt-2">
                      <Col lg={8} sm={8} xs={8} md={7}>
                        <div className="" style={subtotal}>{translate('Tax')} {gst}%</div>
                      </Col>
                      <Col lg={4} sm={4} xs={4} md={5}>
                        <div className="text-end" style={subtotal}>RM {tax}</div>
                      </Col>
                    </Row>


                    <Row className="mt-2">
                      <Col lg={8} sm={8} xs={8} md={7}>
                        <div className="" style={total}>{translate('Total')}</div>
                      </Col>
                      <Col lg={4} sm={4} xs={4} md={5}>
                        <div className="text-end" style={total}>RM {totals}</div>
                      </Col>
                    </Row>
                    {/* <Form.Group controlId="formAgreement" className="mt-5 d-flex align-items-center"> */}

                    <input
                      type="checkbox" style={check} className={(verifyTerms ? false : 'errorField ', 'order-check-form mt-5 me-2')} onChange={() => checkTerms()}
                      checked={isChecked}
                      label=""
                    /><span>我特此同意为我的孩子订餐 , 如有任何变更, 我会通过WhatsApp通知食堂。
                      I hereby agree to have catering service for my children, if there are any changes, will inform Canteen via WhatsApp.</span>
                    {/* </Form.Group> */}
                    {
                      verifyTerms ? false : <p style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>
                        {translate('Please Check Terms and condition')}</p>
                    }
                    {/* <Form.Group controlId="formAgreement" className="mt-5 d-flex align-items-center"> */}
                    <br />
                    <input
                      type="checkbox" style={check} className={(verifyTerms ? false : 'errorField', 'order-check-form mt-5 me-2')} onChange={() => checkPolicy()}
                      checked={isChecked1}
                      label=""
                    />我特此确认，我已理解并同意，无论任何形式的缺席，费用均不可退还或挪后使用。
                    I hereby confirm that I have understand and agree that regardless of any form of absenteeism, the fee is non- refundable or carry forward.
                    {/* </Form.Group> */}
                    {
                      verifyPolicy ? false : <p style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>{translate('Please Check Privacy Policy')}</p>
                    }

                    <div className='mt-3'>
                      <span>Payment Method:</span>
                      <div>
                        <label>
                          <input
                            type="radio"
                            value="1"
                            checked={selectedValue == '1'}
                            onChange={handleRadioChange}
                            className='me-1'
                          />
                          E-Wallet
                        </label>
                      </div>
                      <div>
                        <label>
                          <input
                            type="radio"
                            value="2"
                            checked={selectedValue == '2'}
                            onChange={handleRadioChange}
                            className='me-1 '
                          />
                          Online Payment
                        </label>
                      </div>
                      {
                        verifyPayMethod ? false : <p style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>{translate('Please Choose a payment method.')}</p>
                      }
                    </div>

                    <Button style={{
                      width: '100%',
                      backgroundColor: '#E85C33',
                      borderColor: '#E85C33',
                      // maxHeight: '40px'
                    }} className="mt-5 mx-1" onClick={() => { proceedToPay() }}>
                      {btnLoading ? <div className="btn-loader">
                        <div className=" btn-spinner"></div>
                      </div> : translate('Proceed to Pay')}
                    </Button>


                  </div>
                </Col>
              )}
            </Row>
            <Modal dialogClassName="modal-dialog-centered"
              centered
              show={showSelectChildModal1}
              onHide={() => setShowSelectChildModal1(false)}

            >
              <div className="my-4">
                <div className="text-center mt-5"><img src={process.env.PUBLIC_URL + '/save.png'}></img></div>
                <div className="text-center mt-3 " style={{ fontSize: '20px', fontWeight: 'bold' }}>{translate('Delete Menu')} </div>
                <div className="text-center my-3" style={{ color: '#6B6B6B' }}>{translate('Are You Sure, You want to delete Menu ?')}</div>
                <Row>
                  <Col xl={6} lg={6} md={6}><Button style={{ background: 'var(--layout-100, #F9F9F9)', color: 'black', border: 'unset', float: 'right' }} onClick={handleSelectChildHide1}>{translate('Cancel')}</Button></Col>
                  <Col xl={6} lg={6} md={6}><Button onClick={removeCart} style={{ background: 'var(--buttons-red-700, #C84242)', color: 'white', border: 'unset' }}  >{translate('Delete')}</Button></Col>
                </Row>
              </div>


            </Modal>
            <Modal dialogClassName="modal-dialog-centered"
              centered
              show={showSelectChildModal}
              onHide={() => setShowSelectChildModal(false)}

            >
              <div className="my-4">
                <div className="text-center mt-5"><img src="save.png"></img></div>
                <div className="text-center mt-3 " style={{ fontSize: '20px', fontWeight: 'bold' }}>{translate('Success')}</div>
                <div className="text-center my-3" style={{ color: '#6B6B6B' }}>{translate('Product Successfully Added')}</div>
                <div className="text-center my-3 mx-3" style={{ color: '#E85C33' }}>
                  *{translate('Please download and MUST print out QR, students need to scan card in order to get food.')}
                </div>
              </div>

            </Modal>

            <Modal show={showMenuInfo} onHide={() => setShowMenuInfo(false)} dialogClassName="modal-dialog-centered"
              centered>
              <Modal.Header closeButton>
                <Modal.Title className="" style={{ fontSize: '17px' }} >
                  {cartDetail ?
                    <>
                      {translate(infoInd.MenuTypeEnglish)}
                    </> : null}

                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="modal-body-scrollable" style={{ maxHeight: '60vh', overflowY: 'auto', overflowX: 'hidden' }} >
                {cartDetail ?
                  <div className='mt-3'>

                    {/* <div>
                      <Row>
                        <Col lg={8} xs={9}>
                          <div style={{ color: '#6B6B6B', fontSize: '17px', fontStyle: 'normal', fontWeight: 500 }}>
                            {cartDetail ?
                              <>
                                {infoInd.CartType == 'day' ? 'Customized Day' : 'Month'}
                              </> : null}

                          </div>
                        </Col>
                        <Col lg={4} xs={3}>
                          <div style={{ fontSize: '17px', color: '#E85C33', fontWeight: 500 }} className='text-end me-2'>
                            {infoInd.CartMonth}
                          </div>
                        </Col>
                      </Row>
                      <hr />
                      {infoInd.CartType == 'day' ?
                        <>
                          {
                            Object.entries(infoInd.CartDates)?.map(([key, value]) => {
                              return (
                                <>
                                  <Row className='mt-3'>
                                    <Col style={{ fontFamily: 'Rubik', fontSize: '17px', color: '#1A1A1A', fontWeight: 500 }}>
                                      {dayNamesMapping[key]}
                                    </Col>

                                    <Col style={{ fontFamily: 'Rubik', color: '#E85C33', fontWeight: 500 }} className='text-end'>
                                      {value}
                                    </Col>
                                  </Row>
                                </>
                              )
                            })
                          }
                          <hr />
                        </>


                        : null}


                    </div> */}

                    {/* )
                  })} */}
                    {selectedLanguage === 'en' && (
                      <>
                        <div dangerouslySetInnerHTML={{ __html: infoInd.MenuRemarksTopEnglish }} />
                        <hr style={{ borderColor: '#ccc' }} />
                        <div dangerouslySetInnerHTML={{ __html: infoInd.MenuDescriptionEnglish }} />
                        <hr style={{ borderColor: '#ccc' }} />
                        <div dangerouslySetInnerHTML={{ __html: infoInd.MenuRemarksBottomEnglish }} />
                      </>

                    )}
                    {selectedLanguage === 'es' && (
                      <>
                        <div dangerouslySetInnerHTML={{ __html: infoInd.MenuRemarksTopChinese }} />
                        <hr style={{ borderColor: '#ccc' }} />
                        <div dangerouslySetInnerHTML={{ __html: infoInd.MenuDescriptionChinese }} />
                        <hr style={{ borderColor: '#ccc' }} />
                        <div dangerouslySetInnerHTML={{ __html: infoInd.MenuRemarksBottomChinese }} />
                      </>

                    )}

                  </div> : null}

                {/* : null
                } */}
              </Modal.Body>

            </Modal>
            <Modal show={modalVisible} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Insufficient E-Wallet</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Do you want to add Money to Wallet?
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Cancel
                </Button>
                <Button variant="primary" onClick={handleConfirm}>
                  Yes
                </Button>
              </Modal.Footer>
            </Modal>
          </Container>
          {/* ... (the rest of your code) ... */}
        </>
      )}
    </div>
  );




}








